/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
div.awsui_column-layout_vvxn7_1nxcc_177:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  word-wrap: break-word;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212:not(#\9) {
  margin-block: calc(var(--space-grid-gutter-ieqn7e, 20px) / -2);
  margin-inline: calc(var(--space-grid-gutter-ieqn7e, 20px) / -2);
  /* stylelint-disable-next-line selector-max-universal */
  /* stylelint-disable-next-line selector-max-universal */
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-no-gutters_vvxn7_1nxcc_218:not(#\9) {
  margin-block: 0;
  margin-inline: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-variant-text-grid_vvxn7_1nxcc_222:not(#\9) {
  margin-block: calc(-1 * var(--space-grid-gutter-ieqn7e, 20px) / 2);
  margin-inline: calc(-1 * var(--space-grid-gutter-ieqn7e, 20px));
  /* stylelint-disable-next-line selector-max-universal */
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-variant-text-grid_vvxn7_1nxcc_222 > *:not(#\9) {
  border-inline-start: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-variant-text-grid_vvxn7_1nxcc_222.awsui_grid-breakpoint-default_vvxn7_1nxcc_230 > *:not(#\9):nth-child(1n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-variant-text-grid_vvxn7_1nxcc_222.awsui_grid-columns-1_vvxn7_1nxcc_233.awsui_grid-breakpoint-xxs_vvxn7_1nxcc_233 > *:not(#\9):nth-child(1n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-variant-text-grid_vvxn7_1nxcc_222.awsui_grid-columns-1_vvxn7_1nxcc_233.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-child(1n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-variant-text-grid_vvxn7_1nxcc_222.awsui_grid-columns-2_vvxn7_1nxcc_239.awsui_grid-breakpoint-xxs_vvxn7_1nxcc_233 > *:not(#\9):nth-child(2n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-variant-text-grid_vvxn7_1nxcc_222.awsui_grid-columns-2_vvxn7_1nxcc_239.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-child(2n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-variant-text-grid_vvxn7_1nxcc_222.awsui_grid-columns-3_vvxn7_1nxcc_245.awsui_grid-breakpoint-xxs_vvxn7_1nxcc_233 > *:not(#\9):nth-child(2n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-variant-text-grid_vvxn7_1nxcc_222.awsui_grid-columns-3_vvxn7_1nxcc_245.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-child(3n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-variant-text-grid_vvxn7_1nxcc_222.awsui_grid-columns-4_vvxn7_1nxcc_251.awsui_grid-breakpoint-xxs_vvxn7_1nxcc_233 > *:not(#\9):nth-child(2n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-variant-text-grid_vvxn7_1nxcc_222.awsui_grid-columns-4_vvxn7_1nxcc_251.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-child(4n+1) {
  border-inline-start-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212:not(#\9):not(.awsui_grid-no-gutters_vvxn7_1nxcc_218) > * {
  padding-block: calc(var(--space-grid-gutter-ieqn7e, 20px) / 2);
  padding-inline: calc(var(--space-grid-gutter-ieqn7e, 20px) / 2);
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212:not(#\9):not(.awsui_grid-no-gutters_vvxn7_1nxcc_218).awsui_grid-variant-text-grid_vvxn7_1nxcc_222 > * {
  padding-block: 0;
  padding-inline: var(--space-grid-gutter-ieqn7e, 20px);
  margin-block: calc(var(--space-grid-gutter-ieqn7e, 20px) / 2);
  margin-inline: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-vertical-borders_vvxn7_1nxcc_267:not(#\9) {
  /* stylelint-disable-next-line selector-max-universal */
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-vertical-borders_vvxn7_1nxcc_267 > *:not(#\9) {
  border-inline-end: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-vertical-borders_vvxn7_1nxcc_267.awsui_grid-breakpoint-default_vvxn7_1nxcc_230 > *:not(#\9):nth-child(1n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-vertical-borders_vvxn7_1nxcc_267.awsui_grid-columns-1_vvxn7_1nxcc_233.awsui_grid-breakpoint-xxs_vvxn7_1nxcc_233 > *:not(#\9):nth-child(1n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-vertical-borders_vvxn7_1nxcc_267.awsui_grid-columns-1_vvxn7_1nxcc_233.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-child(1n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-vertical-borders_vvxn7_1nxcc_267.awsui_grid-columns-2_vvxn7_1nxcc_239.awsui_grid-breakpoint-xxs_vvxn7_1nxcc_233 > *:not(#\9):nth-child(2n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-vertical-borders_vvxn7_1nxcc_267.awsui_grid-columns-2_vvxn7_1nxcc_239.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-child(2n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-vertical-borders_vvxn7_1nxcc_267.awsui_grid-columns-3_vvxn7_1nxcc_245.awsui_grid-breakpoint-xxs_vvxn7_1nxcc_233 > *:not(#\9):nth-child(2n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-vertical-borders_vvxn7_1nxcc_267.awsui_grid-columns-3_vvxn7_1nxcc_245.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-child(3n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-vertical-borders_vvxn7_1nxcc_267.awsui_grid-columns-4_vvxn7_1nxcc_251.awsui_grid-breakpoint-xxs_vvxn7_1nxcc_233 > *:not(#\9):nth-child(2n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-vertical-borders_vvxn7_1nxcc_267.awsui_grid-columns-4_vvxn7_1nxcc_251.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-child(4n) {
  border-inline-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-horizontal-borders_vvxn7_1nxcc_300:not(#\9) {
  /* stylelint-disable selector-max-universal */
  /* stylelint-enable selector-max-universal */
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-horizontal-borders_vvxn7_1nxcc_300 > *:not(#\9) {
  border-block-end: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-horizontal-borders_vvxn7_1nxcc_300 > *:not(#\9):last-child {
  border-block-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-horizontal-borders_vvxn7_1nxcc_300.awsui_grid-columns-2_vvxn7_1nxcc_239.awsui_grid-breakpoint-xxs_vvxn7_1nxcc_233 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-horizontal-borders_vvxn7_1nxcc_300.awsui_grid-columns-2_vvxn7_1nxcc_239.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-horizontal-borders_vvxn7_1nxcc_300.awsui_grid-columns-3_vvxn7_1nxcc_245.awsui_grid-breakpoint-xxs_vvxn7_1nxcc_233 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-horizontal-borders_vvxn7_1nxcc_300.awsui_grid-columns-3_vvxn7_1nxcc_245.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-last-child(2):nth-child(3n+1) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-horizontal-borders_vvxn7_1nxcc_300.awsui_grid-columns-3_vvxn7_1nxcc_245.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-last-child(2):nth-child(3n+2) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-horizontal-borders_vvxn7_1nxcc_300.awsui_grid-columns-3_vvxn7_1nxcc_245.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-last-child(3):nth-child(3n+1) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-horizontal-borders_vvxn7_1nxcc_300.awsui_grid-columns-4_vvxn7_1nxcc_251.awsui_grid-breakpoint-xxs_vvxn7_1nxcc_233 > *:not(#\9):nth-last-child(2):nth-child(2n+1) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-horizontal-borders_vvxn7_1nxcc_300.awsui_grid-columns-4_vvxn7_1nxcc_251.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-last-child(2):nth-child(4n+1) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-horizontal-borders_vvxn7_1nxcc_300.awsui_grid-columns-4_vvxn7_1nxcc_251.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-last-child(2):nth-child(4n+2) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-horizontal-borders_vvxn7_1nxcc_300.awsui_grid-columns-4_vvxn7_1nxcc_251.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-last-child(2):nth-child(4n+3) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-horizontal-borders_vvxn7_1nxcc_300.awsui_grid-columns-4_vvxn7_1nxcc_251.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-last-child(3):nth-child(4n+1) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-horizontal-borders_vvxn7_1nxcc_300.awsui_grid-columns-4_vvxn7_1nxcc_251.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-last-child(3):nth-child(4n+2) {
  border-block-end-width: 0;
}
div.awsui_column-layout_vvxn7_1nxcc_177 > .awsui_grid_vvxn7_1nxcc_212.awsui_grid-horizontal-borders_vvxn7_1nxcc_300.awsui_grid-columns-4_vvxn7_1nxcc_251.awsui_grid-breakpoint-xs_vvxn7_1nxcc_236 > *:not(#\9):nth-last-child(4):nth-child(4n+1) {
  border-block-end-width: 0;
}