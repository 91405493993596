/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_7nfqu_14dgv_149:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  background-color: var(--color-background-layout-main-cp7lkl, #ffffff);
  --awsui-max-content-width-g964ok: 100%;
  display: grid;
  grid-template-areas: "toolbar    toolbar     toolbar" ".       notifications  ." ".           main       .";
  grid-template-columns: var(--space-layout-content-horizontal-7l52k3, 24px) minmax(0, 1fr) var(--space-layout-content-horizontal-7l52k3, 24px);
  grid-template-rows: min-content min-content 1fr;
}
@media (min-width: 689px) {
  .awsui_root_7nfqu_14dgv_149:not(#\9) {
    grid-template-areas: "toolbar    toolbar      toolbar       toolbar   toolbar         toolbar  toolbar" "navigation .         notifications    .         sideSplitPanel  tools    global-tools" "navigation .             main         .         sideSplitPanel  tools    global-tools";
    grid-template-columns: min-content minmax(var(--space-layout-content-horizontal-7l52k3, 24px), 1fr) minmax(0, var(--awsui-max-content-width-g964ok)) minmax(var(--space-layout-content-horizontal-7l52k3, 24px), 1fr) min-content min-content;
    grid-template-rows: min-content min-content 1fr min-content;
  }
}
@media (min-width: 689px) and (min-width: 1401px) {
  .awsui_root_7nfqu_14dgv_149.awsui_has-adaptive-widths-default_7nfqu_14dgv_198:not(#\9) {
    --awsui-max-content-width-g964ok: 1280px;
  }
}
@media (min-width: 689px) and (min-width: 1921px) {
  .awsui_root_7nfqu_14dgv_149.awsui_has-adaptive-widths-default_7nfqu_14dgv_198:not(#\9) {
    --awsui-max-content-width-g964ok: 1440px;
  }
}
@media (min-width: 689px) and (min-width: 2541px) {
  .awsui_root_7nfqu_14dgv_149.awsui_has-adaptive-widths-default_7nfqu_14dgv_198:not(#\9) {
    --awsui-max-content-width-g964ok: 1620px;
  }
}
@media (min-width: 689px) and (min-width: 1401px) {
  .awsui_root_7nfqu_14dgv_149.awsui_has-adaptive-widths-dashboard_7nfqu_14dgv_213:not(#\9) {
    --awsui-max-content-width-g964ok: 1280px;
  }
}
@media (min-width: 689px) and (min-width: 1921px) {
  .awsui_root_7nfqu_14dgv_149.awsui_has-adaptive-widths-dashboard_7nfqu_14dgv_213:not(#\9) {
    --awsui-max-content-width-g964ok: 1620px;
  }
}
@media (min-width: 689px) and (min-width: 2541px) {
  .awsui_root_7nfqu_14dgv_149.awsui_has-adaptive-widths-dashboard_7nfqu_14dgv_213:not(#\9) {
    --awsui-max-content-width-g964ok: 2160px;
  }
}

.awsui_navigation_7nfqu_14dgv_228:not(#\9),
.awsui_tools_7nfqu_14dgv_229:not(#\9),
.awsui_global-tools_7nfqu_14dgv_230:not(#\9) {
  grid-row: 1/-1;
  grid-column: 1/-1;
  background: var(--color-background-container-content-epgqkc, #ffffff);
  opacity: 1;
}
@media (max-width: 688px) {
  .awsui_navigation_7nfqu_14dgv_228:not(#\9),
  .awsui_tools_7nfqu_14dgv_229:not(#\9),
  .awsui_global-tools_7nfqu_14dgv_230:not(#\9) {
    inline-size: 100%;
  }
}

.awsui_navigation_7nfqu_14dgv_228:not(#\9) {
  z-index: 830;
}
@media (min-width: 689px) {
  .awsui_navigation_7nfqu_14dgv_228:not(#\9) {
    grid-area: navigation;
    inline-size: var(--awsui-navigation-width-g964ok);
    border-inline-end: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-layout-hrw5xj, #b4b4bb);
  }
}
@media (max-width: 688px) {
  .awsui_navigation_7nfqu_14dgv_228:not(#\9) {
    z-index: 1001;
  }
}

@media (min-width: 689px) {
  .awsui_tools_7nfqu_14dgv_229:not(#\9) {
    grid-area: tools;
    /* stylelint-disable plugin/no-unsupported-browser-features */
  }
  .awsui_tools_7nfqu_14dgv_229:not(#\9):not(:has(> [data-testid])) {
    inline-size: var(--awsui-tools-width-g964ok);
  }
  .awsui_tools_7nfqu_14dgv_229:not(#\9):not(:has(> [data-testid])).awsui_tools-open_7nfqu_14dgv_268 {
    border-inline-start: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-layout-hrw5xj, #b4b4bb);
  }
}

@media (min-width: 689px) {
  .awsui_global-tools_7nfqu_14dgv_230:not(#\9) {
    display: flex;
    grid-area: global-tools;
  }
}

@media (min-width: 689px) {
  .awsui_split-panel-side_7nfqu_14dgv_281:not(#\9) {
    grid-area: sideSplitPanel;
    border-inline-start: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-layout-hrw5xj, #b4b4bb);
    opacity: 1;
  }
}

.awsui_split-panel-bottom_7nfqu_14dgv_288:not(#\9) {
  position: sticky;
  z-index: 840;
  align-self: end;
  grid-area: main;
  grid-column: 1/-1;
}
@media (min-width: 689px) {
  .awsui_split-panel-bottom_7nfqu_14dgv_288:not(#\9) {
    grid-column: 2/5;
  }
}

.awsui_panel-hidden_7nfqu_14dgv_301:not(#\9) {
  border-block: none;
  border-inline: none;
  opacity: 0;
  z-index: 0;
}
.awsui_panel-hidden_7nfqu_14dgv_301.awsui_navigation_7nfqu_14dgv_228:not(#\9), .awsui_panel-hidden_7nfqu_14dgv_301.awsui_tools_7nfqu_14dgv_229:not(#\9), .awsui_panel-hidden_7nfqu_14dgv_301.awsui_global-tools_7nfqu_14dgv_230:not(#\9) {
  inline-size: 0px;
}

.awsui_toolbar-container_7nfqu_14dgv_311:not(#\9) {
  grid-area: toolbar;
  block-size: 42px;
  border-block-end: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-layout-hrw5xj, #b4b4bb);
}

.awsui_notifications-container_7nfqu_14dgv_317:not(#\9) {
  grid-area: notifications;
}

.awsui_notifications-background_7nfqu_14dgv_321:not(#\9) {
  background: var(--color-background-layout-main-cp7lkl, #ffffff);
  grid-area: notifications;
  grid-column: 1/-1;
}
@media (min-width: 689px) {
  .awsui_notifications-background_7nfqu_14dgv_321:not(#\9) {
    grid-column: 2/5;
  }
}

.awsui_main-landmark_7nfqu_14dgv_332:not(#\9) {
  display: contents;
}

.awsui_main_7nfqu_14dgv_332:not(#\9) {
  grid-area: main;
  margin-block-start: var(--space-scaled-s-aqzyko, 12px);
  margin-block-end: var(--space-layout-content-bottom-noqzmw, 40px);
}
.awsui_main-disable-paddings_7nfqu_14dgv_341:not(#\9) {
  margin-block: 0;
  grid-column: 1/-1;
}
@media (min-width: 689px) {
  .awsui_main-disable-paddings_7nfqu_14dgv_341:not(#\9) {
    grid-column: 2/5;
  }
}

.awsui_content-header_7nfqu_14dgv_351:not(#\9) {
  margin-block-end: var(--space-content-header-padding-bottom-y2enua, 16px);
}

.awsui_content_7nfqu_14dgv_351:not(#\9) {
  display: contents;
}

/* stylelint-disable-next-line selector-combinator-disallowed-list, selector-max-universal */
@media (max-width: 688px) {
  .awsui_unfocusable-mobile_7nfqu_14dgv_361 *:not(#\9) {
    visibility: hidden;
  }
}