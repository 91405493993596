/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_gfwv3_1ab1q_189:not(#\9) {
  gap: var(--space-scaled-xs-26e2du, 8px);
}
.awsui_root_gfwv3_1ab1q_189.awsui_horizontal_gfwv3_1ab1q_192:not(#\9) {
  display: flex;
  gap: var(--space-xs-zb16t3, 8px);
  flex-direction: row;
  flex-wrap: wrap;
}
.awsui_root_gfwv3_1ab1q_189.awsui_vertical_gfwv3_1ab1q_198:not(#\9) {
  display: flex;
  flex-direction: column;
}

.awsui_list_gfwv3_1ab1q_203:not(#\9) {
  display: contents;
  list-style: none;
  padding-block: 0;
  padding-inline: 0;
  margin-block: 0;
  margin-inline: 0;
}
.awsui_list_gfwv3_1ab1q_203.awsui_horizontal_gfwv3_1ab1q_192:not(#\9), .awsui_list_gfwv3_1ab1q_203.awsui_vertical_gfwv3_1ab1q_198:not(#\9) {
  display: flex;
  gap: var(--space-xs-zb16t3, 8px);
}
.awsui_list_gfwv3_1ab1q_203.awsui_horizontal_gfwv3_1ab1q_192:not(#\9) {
  flex-direction: row;
  flex-wrap: wrap;
}
.awsui_list_gfwv3_1ab1q_203.awsui_vertical_gfwv3_1ab1q_198:not(#\9) {
  flex-direction: column;
}
.awsui_list_gfwv3_1ab1q_203.awsui_grid_gfwv3_1ab1q_222:not(#\9) {
  display: grid;
  gap: var(--space-xs-zb16t3, 8px);
  grid-template-columns: repeat(auto-fill, 230px);
}
@media (max-width: 688px) {
  .awsui_list_gfwv3_1ab1q_203.awsui_grid_gfwv3_1ab1q_222:not(#\9) {
    display: flex;
    flex-direction: column;
  }
}

.awsui_list-item_gfwv3_1ab1q_234:not(#\9) {
  padding-block: 0;
  padding-inline: 0;
  margin-block: 0;
  margin-inline: 0;
}

.awsui_toggle-container-inline_gfwv3_1ab1q_241:not(#\9) {
  padding-inline-start: var(--space-xs-zb16t3, 8px);
  display: flex;
  align-items: center;
}

.awsui_toggle_gfwv3_1ab1q_241:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: flex;
  align-items: center;
  background-color: transparent;
  border-block: var(--border-width-field-h1g7tw, 2px) solid transparent;
  border-inline: var(--border-width-field-h1g7tw, 2px) solid transparent;
  padding-block: 0;
  padding-inline: 0;
  margin-inline-start: -1px;
  text-underline-offset: 0.25em;
  text-decoration-thickness: 1px;
  color: var(--color-text-link-default-enwvrt, #006ce0);
  font-weight: inherit;
  letter-spacing: normal;
  text-decoration-line: none;
  text-decoration-color: transparent;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-nf6485, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_toggle_gfwv3_1ab1q_241:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_toggle_gfwv3_1ab1q_241:not(#\9), .awsui-mode-entering .awsui_toggle_gfwv3_1ab1q_241:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_toggle_gfwv3_1ab1q_241:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_toggle_gfwv3_1ab1q_241:not(#\9):focus {
  outline: none;
}
.awsui_toggle_gfwv3_1ab1q_241:not(#\9):active {
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_toggle_gfwv3_1ab1q_241:not(#\9):active, .awsui_toggle_gfwv3_1ab1q_241:not(#\9):focus, .awsui_toggle_gfwv3_1ab1q_241:not(#\9):hover {
  text-decoration-line: underline;
  text-decoration-color: currentColor;
}
.awsui_toggle_gfwv3_1ab1q_241:not(#\9):active, .awsui_toggle_gfwv3_1ab1q_241:not(#\9):focus, .awsui_toggle_gfwv3_1ab1q_241:not(#\9):hover {
  text-decoration: none;
  text-decoration-color: transparent;
}
body[data-awsui-focus-visible=true] .awsui_toggle_gfwv3_1ab1q_241:not(#\9):focus {
  outline: 2px dotted transparent;
  border-block: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-item-focused-nv6mhz, #006ce0);
  border-inline: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-item-focused-nv6mhz, #006ce0);
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 var(--border-control-focus-ring-shadow-spread-0ctpjf, 0px) var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_toggle_gfwv3_1ab1q_241 > .awsui_description_gfwv3_1ab1q_336:not(#\9) {
  margin-inline-start: var(--space-xxs-p8yyaw, 4px);
}

.awsui_separator_gfwv3_1ab1q_340:not(#\9) {
  margin-block: 0;
  margin-inline: var(--space-scaled-m-mo5yse, 16px);
  inline-size: var(--border-divider-section-width-1061zr, 1px);
  background-color: var(--color-border-divider-default-cx07f2, #c6c6cd);
}