/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_root_18582_1wlz1_141:not(#\9) {
  display: flex;
}

.awsui_child_18582_1wlz1_145:not(#\9) {
  /* used in test-utils */
}

.awsui_child_18582_1wlz1_145:not(#\9):empty {
  display: none;
}

/*
 * Horizontal variant
 */
.awsui_horizontal_18582_1wlz1_156:not(#\9) {
  flex-direction: row;
  flex-wrap: wrap;
}
.awsui_horizontal-xxxs_18582_1wlz1_160:not(#\9) {
  gap: var(--space-xxxs-zbmxqb, 2px);
}
.awsui_horizontal-xxs_18582_1wlz1_163:not(#\9) {
  gap: var(--space-xxs-p8yyaw, 4px);
}
.awsui_horizontal-xs_18582_1wlz1_166:not(#\9) {
  gap: var(--space-xs-zb16t3, 8px);
}
.awsui_horizontal-s_18582_1wlz1_169:not(#\9) {
  gap: var(--space-s-34lx8l, 12px);
}
.awsui_horizontal-m_18582_1wlz1_172:not(#\9) {
  gap: var(--space-m-udix3p, 16px);
}
.awsui_horizontal-l_18582_1wlz1_175:not(#\9) {
  gap: var(--space-l-t419sm, 20px);
}
.awsui_horizontal-xl_18582_1wlz1_178:not(#\9) {
  gap: var(--space-xl-lmui9r, 24px);
}
.awsui_horizontal-xxl_18582_1wlz1_181:not(#\9) {
  gap: var(--space-xxl-cu2m1r, 32px);
}

/*
 * Vertical variant
 */
.awsui_vertical_18582_1wlz1_188:not(#\9) {
  flex-direction: column;
}
.awsui_vertical-xxxs_18582_1wlz1_191:not(#\9) {
  row-gap: var(--space-xxxs-zbmxqb, 2px);
}
.awsui_vertical-xxs_18582_1wlz1_194:not(#\9) {
  row-gap: var(--space-xxs-p8yyaw, 4px);
}
.awsui_vertical-xs_18582_1wlz1_197:not(#\9) {
  row-gap: var(--space-xs-zb16t3, 8px);
}
.awsui_vertical-s_18582_1wlz1_200:not(#\9) {
  row-gap: var(--space-scaled-s-aqzyko, 12px);
}
.awsui_vertical-m_18582_1wlz1_203:not(#\9) {
  row-gap: var(--space-scaled-m-mo5yse, 16px);
}
.awsui_vertical-l_18582_1wlz1_206:not(#\9) {
  row-gap: var(--space-scaled-l-0hpmd7, 20px);
}
.awsui_vertical-xl_18582_1wlz1_209:not(#\9) {
  row-gap: var(--space-scaled-xl-kswcw7, 24px);
}
.awsui_vertical-xxl_18582_1wlz1_212:not(#\9) {
  row-gap: var(--space-scaled-xxl-wbot5q, 32px);
}

.awsui_align-center_18582_1wlz1_216:not(#\9) {
  align-items: center;
}

.awsui_align-start_18582_1wlz1_220:not(#\9) {
  align-items: start;
}

.awsui_align-end_18582_1wlz1_224:not(#\9) {
  align-items: end;
}