/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_wih1l_6wmvf_149:not(#\9) {
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  inline-size: 100%;
}

.awsui_tools_wih1l_6wmvf_160:not(#\9) {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-block-start: var(--space-scaled-xs-26e2du, 8px);
  padding-block-end: var(--space-table-header-tools-bottom-25ubsj, 0px);
  padding-inline: 0;
}
.awsui_tools-filtering_wih1l_6wmvf_168:not(#\9) {
  max-inline-size: 100%;
  margin-inline-end: var(--space-l-t419sm, 20px);
}
@supports (flex-basis: fit-content) {
  .awsui_tools-filtering_wih1l_6wmvf_168:not(#\9) {
    flex: 1 1 fit-content;
  }
}
@supports not (flex-basis: fit-content) {
  .awsui_tools-filtering_wih1l_6wmvf_168:not(#\9) {
    flex: 1 1 auto;
  }
}
.awsui_tools-align-right_wih1l_6wmvf_182:not(#\9) {
  display: flex;
  margin-inline-start: auto;
}
.awsui_tools-pagination_wih1l_6wmvf_186 + .awsui_tools-preferences_wih1l_6wmvf_186:not(#\9) {
  border-inline-start: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
  box-sizing: border-box;
  margin-inline-start: var(--space-xs-zb16t3, 8px);
  padding-inline-start: var(--space-xs-zb16t3, 8px);
}
.awsui_tools-small_wih1l_6wmvf_192 > .awsui_tools-filtering_wih1l_6wmvf_168:not(#\9) {
  margin-inline-end: 0;
  margin-block-end: var(--space-scaled-xs-26e2du, 8px);
  flex-basis: 100%;
}

.awsui_table_wih1l_6wmvf_198:not(#\9) {
  inline-size: 100%;
  border-spacing: 0;
  position: relative;
  box-sizing: border-box;
}
.awsui_table-layout-fixed_wih1l_6wmvf_204:not(#\9) {
  table-layout: fixed;
}

.awsui_wrapper_wih1l_6wmvf_208:not(#\9) {
  position: relative;
  box-sizing: border-box;
  inline-size: 100%;
  overflow-x: auto;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}
.awsui_wrapper_wih1l_6wmvf_208.awsui_variant-stacked_wih1l_6wmvf_215 > .awsui_table_wih1l_6wmvf_198:not(#\9), .awsui_wrapper_wih1l_6wmvf_208.awsui_variant-stacked_wih1l_6wmvf_215 > .awsui_wrapper-content-measure_wih1l_6wmvf_215:not(#\9), .awsui_wrapper_wih1l_6wmvf_208.awsui_variant-container_wih1l_6wmvf_215 > .awsui_table_wih1l_6wmvf_198:not(#\9), .awsui_wrapper_wih1l_6wmvf_208.awsui_variant-container_wih1l_6wmvf_215 > .awsui_wrapper-content-measure_wih1l_6wmvf_215:not(#\9) {
  padding-inline: var(--space-table-horizontal-8davlm, 20px);
}
.awsui_wrapper_wih1l_6wmvf_208.awsui_variant-container_wih1l_6wmvf_215:not(#\9):not(.awsui_has-footer_wih1l_6wmvf_218) {
  padding-block-end: var(--space-table-content-bottom-9yr834, 4px);
}
.awsui_wrapper_wih1l_6wmvf_208:not(#\9):not(.awsui_has-header_wih1l_6wmvf_221) {
  border-start-end-radius: var(--border-radius-container-wqv1zi, 16px);
  border-start-start-radius: var(--border-radius-container-wqv1zi, 16px);
}
.awsui_wrapper_wih1l_6wmvf_208:not(#\9)::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Safari and Chrome */
}
body[data-awsui-focus-visible=true] .awsui_wrapper_wih1l_6wmvf_208:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: 2px;
  border-start-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-start-end-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-end-radius: var(--border-radius-container-wqv1zi, 16px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}

.awsui_cell-merged_wih1l_6wmvf_238:not(#\9) {
  text-align: center;
  padding-block: 0;
  padding-inline: 0;
}
.awsui_cell-merged_wih1l_6wmvf_238.awsui_has-footer_wih1l_6wmvf_218:not(#\9) {
  /*
  Add a bottom border to the body cell of an empty table as a separator between the
  table and the footer
  */
  border-block-end: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
}
.awsui_cell-merged-content_wih1l_6wmvf_250:not(#\9) {
  box-sizing: border-box;
  inline-size: 100%;
  padding-block-start: var(--space-scaled-m-mo5yse, 16px);
  padding-block-end: var(--space-scaled-l-0hpmd7, 20px);
  padding-inline: var(--space-l-t419sm, 20px);
}
@supports (position: sticky) {
  .awsui_cell-merged-content_wih1l_6wmvf_250:not(#\9) {
    position: sticky;
    inset-inline-start: 0;
    margin-block: 0;
    margin-inline: calc(-2 * var(--space-table-horizontal-8davlm, 20px));
  }
}

.awsui_empty_wih1l_6wmvf_266:not(#\9) {
  color: var(--color-text-empty-o6660v, #656871);
}

.awsui_loading_wih1l_6wmvf_270:not(#\9) {
  /* used in test-utils */
}

/*
The min/max/width token values in Visual Refresh should align
the table header and body cells selection control with the table
filter search icon.
*/
.awsui_selection-control_wih1l_6wmvf_279:not(#\9) {
  box-sizing: border-box;
  max-inline-size: var(--size-table-selection-horizontal-oqvl1b, 40px);
  min-inline-size: var(--size-table-selection-horizontal-oqvl1b, 40px);
  position: relative;
  inline-size: var(--size-table-selection-horizontal-oqvl1b, 40px);
}
.awsui_selection-control_wih1l_6wmvf_279.awsui_selection-control-header_wih1l_6wmvf_286:not(#\9) {
  padding-block: var(--space-scaled-xs-26e2du, 8px);
  padding-inline: var(--space-scaled-l-0hpmd7, 20px);
  border-inline-start: var(--border-item-width-yel47s, 2px) solid transparent;
}

.awsui_header-secondary_wih1l_6wmvf_292:not(#\9) {
  overflow: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  box-sizing: border-box;
  inline-size: 100%;
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
  background: var(--color-background-table-header-inc72b, #ffffff);
}
.awsui_header-secondary_wih1l_6wmvf_292.awsui_variant-full-page_wih1l_6wmvf_304:not(#\9) {
  background: var(--color-background-layout-main-cp7lkl, #ffffff);
}
.awsui_header-secondary_wih1l_6wmvf_292.awsui_variant-stacked_wih1l_6wmvf_215 > .awsui_table_wih1l_6wmvf_198:not(#\9), .awsui_header-secondary_wih1l_6wmvf_292.awsui_variant-container_wih1l_6wmvf_215 > .awsui_table_wih1l_6wmvf_198:not(#\9) {
  padding-inline: var(--space-table-horizontal-8davlm, 20px);
}
.awsui_header-secondary_wih1l_6wmvf_292.awsui_variant-stacked_wih1l_6wmvf_215:not(#\9):not(.awsui_table-has-header_wih1l_6wmvf_310), .awsui_header-secondary_wih1l_6wmvf_292.awsui_variant-container_wih1l_6wmvf_215:not(#\9):not(.awsui_table-has-header_wih1l_6wmvf_310) {
  border-start-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-start-end-radius: var(--border-radius-container-wqv1zi, 16px);
}
.awsui_header-secondary_wih1l_6wmvf_292:not(#\9)::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.awsui_header-secondary_wih1l_6wmvf_292.awsui_table-has-header_wih1l_6wmvf_310:not(#\9) {
  border-block-start: var(--border-divider-list-width-27y3k5, 1px) solid var(--color-border-container-divider-p2uygo, transparent);
}

.awsui_header-controls_wih1l_6wmvf_321:not(#\9) {
  padding-block: var(--space-container-header-top-5qav00, 12px) var(--space-container-header-bottom-vj01hn, 8px);
}
.awsui_header-controls_wih1l_6wmvf_321.awsui_variant-full-page_wih1l_6wmvf_304:not(#\9) {
  padding-block: 0 calc(var(--space-container-header-bottom-vj01hn, 8px) + var(--space-table-header-tools-full-page-bottom-xulfq9, 4px));
}
.awsui_header-controls_wih1l_6wmvf_321.awsui_variant-stacked_wih1l_6wmvf_215:not(#\9), .awsui_header-controls_wih1l_6wmvf_321.awsui_variant-container_wih1l_6wmvf_215:not(#\9) {
  padding-inline: calc(var(--space-table-horizontal-8davlm, 20px) + var(--space-table-header-horizontal-wl3ykr, 0px));
}
.awsui_header-controls_wih1l_6wmvf_321.awsui_variant-embedded_wih1l_6wmvf_330:not(#\9), .awsui_header-controls_wih1l_6wmvf_321.awsui_variant-borderless_wih1l_6wmvf_330:not(#\9) {
  padding-inline: var(--space-table-header-horizontal-wl3ykr, 0px);
  padding-block-start: var(--space-table-embedded-header-top-bfjeam, 0px);
}

.awsui_footer-wrapper_wih1l_6wmvf_335.awsui_variant-stacked_wih1l_6wmvf_215:not(#\9), .awsui_footer-wrapper_wih1l_6wmvf_335.awsui_variant-container_wih1l_6wmvf_215:not(#\9) {
  padding-inline: var(--space-table-horizontal-8davlm, 20px);
}

.awsui_footer_wih1l_6wmvf_335:not(#\9) {
  padding-block: var(--space-scaled-s-aqzyko, 12px);
  padding-inline: var(--space-table-footer-horizontal-ptapb9, 0px);
}
.awsui_footer-with-pagination_wih1l_6wmvf_343:not(#\9) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--space-scaled-s-aqzyko, 12px);
}
.awsui_footer-pagination_wih1l_6wmvf_351:not(#\9) {
  margin-inline-start: auto;
}

.awsui_thead-active_wih1l_6wmvf_355:not(#\9),
.awsui_row_wih1l_6wmvf_356:not(#\9),
.awsui_row-selected_wih1l_6wmvf_357:not(#\9) {
  /* used in test-utils */
}