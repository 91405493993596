/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
@keyframes awsui_modal-slide-up_1d2i7_6hrb7_1 {
  0% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.awsui_dialog_1d2i7_6hrb7_165:not(#\9) {
  animation: awsui_modal-slide-up_1d2i7_6hrb7_1 var(--motion-duration-slow-z2kaah, 180ms) ease-out, awsui_awsui-motion-fade-in-0_1d2i7_6hrb7_1 var(--motion-duration-slow-z2kaah, 180ms) ease-out;
  animation-delay: var(--motion-duration-fast-sbm3r7, 90ms);
  animation-fill-mode: both;
}
@keyframes awsui_awsui-motion-fade-in-0_1d2i7_6hrb7_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_dialog_1d2i7_6hrb7_165:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_dialog_1d2i7_6hrb7_165:not(#\9), .awsui-mode-entering .awsui_dialog_1d2i7_6hrb7_165:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_dialog_1d2i7_6hrb7_165.awsui_refresh_1d2i7_6hrb7_188:not(#\9) {
  animation: awsui_awsui-motion-scale-popup_1d2i7_6hrb7_1, awsui_awsui-motion-fade-in-0_1d2i7_6hrb7_1;
  animation-duration: var(--motion-duration-refresh-only-fast-4jn2d7, 115ms);
  animation-timing-function: var(--motion-easing-refresh-only-a-8flf9q, cubic-bezier(0, 0, 0, 1));
  animation-fill-mode: both;
}
@keyframes awsui_awsui-motion-fade-in-0_1d2i7_6hrb7_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes awsui_awsui-motion-scale-popup_1d2i7_6hrb7_1 {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_dialog_1d2i7_6hrb7_165.awsui_refresh_1d2i7_6hrb7_188:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_dialog_1d2i7_6hrb7_165.awsui_refresh_1d2i7_6hrb7_188:not(#\9), .awsui-mode-entering .awsui_dialog_1d2i7_6hrb7_165.awsui_refresh_1d2i7_6hrb7_188:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_root_1d2i7_6hrb7_221:not(#\9) {
  animation: awsui_awsui-motion-fade-in_1d2i7_6hrb7_1 var(--motion-duration-extra-slow-ngjb9g, 270ms) ease-out;
  animation-fill-mode: both;
}
@keyframes awsui_awsui-motion-fade-in_1d2i7_6hrb7_1 {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_root_1d2i7_6hrb7_221:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_root_1d2i7_6hrb7_221:not(#\9), .awsui-mode-entering .awsui_root_1d2i7_6hrb7_221:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_root_1d2i7_6hrb7_221.awsui_refresh_1d2i7_6hrb7_188:not(#\9) {
  animation-duration: var(--motion-duration-refresh-only-fast-4jn2d7, 115ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_root_1d2i7_6hrb7_221.awsui_refresh_1d2i7_6hrb7_188:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_root_1d2i7_6hrb7_221.awsui_refresh_1d2i7_6hrb7_188:not(#\9), .awsui-mode-entering .awsui_root_1d2i7_6hrb7_221.awsui_refresh_1d2i7_6hrb7_188:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_root_1d2i7_6hrb7_221:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  background-color: var(--color-background-modal-overlay-lal4at, rgba(35, 43, 55, 0.7));
  display: flex;
  align-items: center;
  justify-items: center;
  inset: 0;
  position: fixed;
  z-index: 5000;
  outline: 0;
  overflow: auto;
  cursor: pointer;
}
.awsui_root_1d2i7_6hrb7_221.awsui_hidden_1d2i7_6hrb7_301:not(#\9) {
  display: none;
}

.awsui_focus-lock_1d2i7_6hrb7_305:not(#\9) {
  align-self: flex-start;
  margin-block: auto;
  margin-inline: auto;
  padding-block: var(--space-s-34lx8l, 12px);
  padding-inline: 0;
  z-index: 5000;
  pointer-events: none;
}

.awsui_dialog_1d2i7_6hrb7_165:not(#\9) {
  position: static;
  inset-block-start: 0;
  transform: translate(0, 0);
  inline-size: calc(100vw - var(--space-s-34lx8l, 12px) * 2);
  box-sizing: border-box;
  outline: none;
  z-index: 5000;
  pointer-events: all;
}
.awsui_dialog_1d2i7_6hrb7_165.awsui_small_1d2i7_6hrb7_325:not(#\9) {
  max-inline-size: 320px;
}
.awsui_dialog_1d2i7_6hrb7_165.awsui_medium_1d2i7_6hrb7_328:not(#\9) {
  max-inline-size: 600px;
}
.awsui_dialog_1d2i7_6hrb7_165.awsui_large_1d2i7_6hrb7_331:not(#\9) {
  max-inline-size: 820px;
}
.awsui_dialog_1d2i7_6hrb7_165.awsui_max_1d2i7_6hrb7_334.awsui_breakpoint-xs_1d2i7_6hrb7_334:not(#\9) {
  max-inline-size: calc(100vw - (8 * 10px + var(--space-xxxl-4x2gki, 40px)));
  margin-block: auto;
  margin-inline: auto;
}

.awsui_container_1d2i7_6hrb7_340:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: block;
  background-color: var(--color-background-container-content-epgqkc, #ffffff);
  word-wrap: break-word;
  border-block-start: var(--border-container-top-width-uugwwl, 0px) solid var(--color-border-container-top-736buh, transparent);
  border-start-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-start-end-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-end-radius: var(--border-radius-container-wqv1zi, 16px);
  box-shadow: var(--shadow-modal-2ennni, 0px 4px 20px 1px rgba(0, 7, 22, 0.1));
}

.awsui_content_1d2i7_6hrb7_384:not(#\9) {
  padding-block-start: var(--space-container-content-top-1ae0r3, 4px);
  padding-block-end: var(--space-modal-content-bottom-hqahub, 16px);
  padding-inline: var(--space-modal-horizontal-f89syl, 20px);
}
.awsui_content_1d2i7_6hrb7_384.awsui_no-paddings_1d2i7_6hrb7_389:not(#\9) {
  padding-block: 0;
  padding-inline: 0;
}

.awsui_header_1d2i7_6hrb7_394:not(#\9) {
  padding-block-start: var(--space-container-header-top-5qav00, 12px);
  padding-block-end: var(--space-container-header-bottom-vj01hn, 8px);
  padding-inline: var(--space-modal-horizontal-f89syl, 20px);
  background-color: var(--color-background-container-header-clzg6q, #ffffff);
  border-block-end: 1px solid var(--color-border-container-divider-p2uygo, transparent);
  border-start-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-start-end-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

.awsui_header--text_1d2i7_6hrb7_406:not(#\9) {
  /* used in test-utils */
}

.awsui_footer_1d2i7_6hrb7_410:not(#\9) {
  border-block-start: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
  padding-block: var(--space-scaled-s-aqzyko, 12px);
  padding-inline: var(--space-container-horizontal-wfukh3, 20px);
  background-color: var(--color-background-container-content-epgqkc, #ffffff);
  position: sticky;
  inset-block-end: 0;
  z-index: 800;
}
.awsui_footer--stuck_1d2i7_6hrb7_419:not(#\9) {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-end-radius: var(--border-radius-container-wqv1zi, 16px);
}
.awsui_footer_1d2i7_6hrb7_410:not(#\9):after {
  content: "";
  display: table;
  clear: both;
}

.awsui_dismiss-control_1d2i7_6hrb7_431:not(#\9) {
  /* used in test-utils */
}

.awsui_modal-open_1d2i7_6hrb7_435:not(#\9) {
  overflow: hidden;
  /*
   * When padding-right is added to account for scrollbar being turned
   * off by overflow:hidden, that padding will go offscreen and have no
   * effect if box-sizing is content-box and body width is 100%.  Set
   * border-box to avoid this; this should be safe as box-sizing isn't
   * inherited by child elements normally.
   */
  box-sizing: border-box;
}