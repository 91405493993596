/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_button-trigger_18eso_4gd9x_157 > .awsui_arrow_18eso_4gd9x_157:not(#\9) {
  transition: transform var(--motion-duration-rotate-180-dpvl4m, 135ms) var(--motion-easing-rotate-180-e270ko, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_button-trigger_18eso_4gd9x_157 > .awsui_arrow_18eso_4gd9x_157:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_button-trigger_18eso_4gd9x_157 > .awsui_arrow_18eso_4gd9x_157:not(#\9), .awsui-mode-entering .awsui_button-trigger_18eso_4gd9x_157 > .awsui_arrow_18eso_4gd9x_157:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_button-trigger_18eso_4gd9x_157:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  position: relative;
  display: flex;
  inline-size: 100%;
  justify-content: space-between;
  align-items: center;
  padding-block: var(--space-scaled-xxs-7597g1, 4px);
  padding-inline: var(--space-field-horizontal-gg19kw, 12px);
  background-color: var(--color-background-input-default-k0a6tw, #ffffff);
  border-start-start-radius: var(--border-radius-input-plgbrq, 8px);
  border-start-end-radius: var(--border-radius-input-plgbrq, 8px);
  border-end-start-radius: var(--border-radius-input-plgbrq, 8px);
  border-end-end-radius: var(--border-radius-input-plgbrq, 8px);
  border-block: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-input-default-l7v83d, #8c8c94);
  border-inline: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-input-default-l7v83d, #8c8c94);
  min-block-size: var(--size-vertical-input-v5iwwf, 32px);
}
.awsui_button-trigger_18eso_4gd9x_157.awsui_in-filtering-token-root_18eso_4gd9x_220:not(#\9), .awsui_button-trigger_18eso_4gd9x_157.awsui_in-filtering-token-nested_18eso_4gd9x_220:not(#\9) {
  padding-block: 0px;
  padding-inline: var(--space-field-horizontal-gg19kw, 12px);
  border-color: var(--color-border-item-selected-m769ej, #006ce0);
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  block-size: 100%;
  min-block-size: unset;
}
body[data-awsui-focus-visible=true] .awsui_button-trigger_18eso_4gd9x_157.awsui_in-filtering-token-root_18eso_4gd9x_220:not(#\9):focus, body[data-awsui-focus-visible=true] .awsui_button-trigger_18eso_4gd9x_157.awsui_in-filtering-token-nested_18eso_4gd9x_220:not(#\9):focus {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_button-trigger_18eso_4gd9x_157.awsui_in-filtering-token-root_18eso_4gd9x_220:not(#\9):focus, body[data-awsui-focus-visible=true] .awsui_button-trigger_18eso_4gd9x_157.awsui_in-filtering-token-nested_18eso_4gd9x_220:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-filtering-token-operation-select-focus-outline-gutter-0ailok, -5px) - 1px);
}
body[data-awsui-focus-visible=true] .awsui_button-trigger_18eso_4gd9x_157.awsui_in-filtering-token-root_18eso_4gd9x_220:not(#\9):focus::before, body[data-awsui-focus-visible=true] .awsui_button-trigger_18eso_4gd9x_157.awsui_in-filtering-token-nested_18eso_4gd9x_220:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * var(--space-filtering-token-operation-select-focus-outline-gutter-0ailok, -5px));
  inset-block-start: calc(-1 * var(--space-filtering-token-operation-select-focus-outline-gutter-0ailok, -5px));
  inline-size: calc(100% + var(--space-filtering-token-operation-select-focus-outline-gutter-0ailok, -5px) + var(--space-filtering-token-operation-select-focus-outline-gutter-0ailok, -5px));
  block-size: calc(100% + var(--space-filtering-token-operation-select-focus-outline-gutter-0ailok, -5px) + var(--space-filtering-token-operation-select-focus-outline-gutter-0ailok, -5px));
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_button-trigger_18eso_4gd9x_157.awsui_in-filtering-token-nested_18eso_4gd9x_220:not(#\9) {
  border-start-start-radius: calc(var(--border-radius-input-plgbrq, 8px) / 2);
  border-end-start-radius: calc(var(--border-radius-input-plgbrq, 8px) / 2);
}
.awsui_button-trigger_18eso_4gd9x_157.awsui_has-caret_18eso_4gd9x_254:not(#\9) {
  padding-inline-end: var(--space-field-icon-offset-csk9vw, 36px);
}
.awsui_button-trigger_18eso_4gd9x_157 > .awsui_placeholder_18eso_4gd9x_257:not(#\9) {
  color: var(--color-text-input-placeholder-f3x213, #656871);
  font-style: italic;
}
.awsui_button-trigger_18eso_4gd9x_157 > .awsui_arrow_18eso_4gd9x_157:not(#\9) {
  position: absolute;
  inset-inline-end: var(--space-field-horizontal-gg19kw, 12px);
  inset-block-start: calc(50% - var(--line-height-body-m-30ar75, 20px) / 2);
  color: var(--color-text-button-inline-icon-default-p881yb, #006ce0);
}
.awsui_button-trigger_18eso_4gd9x_157:not(#\9):hover > .awsui_arrow_18eso_4gd9x_157 {
  color: var(--color-text-button-inline-icon-hover-vcdou1, #002b66);
}
.awsui_button-trigger_18eso_4gd9x_157.awsui_pressed_18eso_4gd9x_270 > .awsui_arrow_18eso_4gd9x_157:not(#\9) {
  transform: rotate(-180deg);
}
.awsui_button-trigger_18eso_4gd9x_157.awsui_disabled_18eso_4gd9x_273:not(#\9) {
  background-color: var(--color-background-input-disabled-gvxsk4, #ebebf0);
  border-block: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-input-disabled-lb7zn3, #ebebf0);
  border-inline: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-input-disabled-lb7zn3, #ebebf0);
  color: var(--color-text-input-disabled-fc4jfl, #b4b4bb);
  cursor: auto;
}
.awsui_button-trigger_18eso_4gd9x_157.awsui_disabled_18eso_4gd9x_273 > .awsui_arrow_18eso_4gd9x_157:not(#\9) {
  color: var(--color-text-button-inline-icon-disabled-yk9lvs, #b4b4bb);
}
.awsui_button-trigger_18eso_4gd9x_157.awsui_disabled_18eso_4gd9x_273.awsui_in-filtering-token_18eso_4gd9x_220:not(#\9) {
  border-color: var(--color-border-control-disabled-qc5csz, #dedee3);
}
.awsui_button-trigger_18eso_4gd9x_157.awsui_disabled_18eso_4gd9x_273 > .awsui_placeholder_18eso_4gd9x_257:not(#\9) {
  color: var(--color-text-input-placeholder-disabled-7v2f7f, #b4b4bb);
}
.awsui_button-trigger_18eso_4gd9x_157.awsui_readonly_18eso_4gd9x_289:not(#\9):not(.awsui_button-trigger_18eso_4gd9x_157.awsui_disabled_18eso_4gd9x_273) {
  background-color: var(--color-background-input-default-k0a6tw, #ffffff);
  border-block: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-input-disabled-lb7zn3, #ebebf0);
  border-inline: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-input-disabled-lb7zn3, #ebebf0);
}
.awsui_button-trigger_18eso_4gd9x_157:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
body[data-awsui-focus-visible=true] .awsui_button-trigger_18eso_4gd9x_157:not(#\9):not(.awsui_in-filtering-token_18eso_4gd9x_220):focus {
  outline: 2px dotted transparent;
  border-block: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-input-focused-c6w5i5, #002b66);
  border-inline: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-input-focused-c6w5i5, #002b66);
  border-start-start-radius: var(--border-radius-input-plgbrq, 8px);
  border-start-end-radius: var(--border-radius-input-plgbrq, 8px);
  border-end-start-radius: var(--border-radius-input-plgbrq, 8px);
  border-end-end-radius: var(--border-radius-input-plgbrq, 8px);
  box-shadow: 0 0 0 var(--border-control-focus-ring-shadow-spread-0ctpjf, 0px) var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_button-trigger_18eso_4gd9x_157:not(#\9):not(.awsui_in-filtering-token_18eso_4gd9x_220):invalid {
  box-shadow: none;
}
.awsui_button-trigger_18eso_4gd9x_157:not(#\9):not(.awsui_in-filtering-token_18eso_4gd9x_220).awsui_invalid_18eso_4gd9x_311, .awsui_button-trigger_18eso_4gd9x_157:not(#\9):not(.awsui_in-filtering-token_18eso_4gd9x_220).awsui_invalid_18eso_4gd9x_311:focus {
  color: var(--color-text-status-error-5676bj, #db0000);
  border-color: var(--color-text-status-error-5676bj, #db0000);
  padding-inline-start: calc(var(--space-field-horizontal-gg19kw, 12px) - (var(--border-invalid-width-5ktjp2, 8px) - var(--border-width-field-h1g7tw, 2px)));
  border-inline-start-width: var(--border-invalid-width-5ktjp2, 8px);
}
.awsui_button-trigger_18eso_4gd9x_157:not(#\9):not(.awsui_in-filtering-token_18eso_4gd9x_220).awsui_invalid_18eso_4gd9x_311:focus, .awsui_button-trigger_18eso_4gd9x_157:not(#\9):not(.awsui_in-filtering-token_18eso_4gd9x_220).awsui_invalid_18eso_4gd9x_311:focus:focus {
  box-shadow: 0 0 0 var(--border-control-invalid-focus-ring-shadow-spread-fhmbjn, 2px) var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_button-trigger_18eso_4gd9x_157:not(#\9):not(.awsui_in-filtering-token_18eso_4gd9x_220).awsui_warning_18eso_4gd9x_320, .awsui_button-trigger_18eso_4gd9x_157:not(#\9):not(.awsui_in-filtering-token_18eso_4gd9x_220).awsui_warning_18eso_4gd9x_320:focus {
  color: var(--color-text-status-warning-csaw41, #855900);
  border-color: var(--color-text-status-warning-csaw41, #855900);
  padding-inline-start: calc(var(--space-field-horizontal-gg19kw, 12px) - (var(--border-invalid-width-5ktjp2, 8px) - var(--border-width-field-h1g7tw, 2px)));
  border-inline-start-width: var(--border-invalid-width-5ktjp2, 8px);
}
.awsui_button-trigger_18eso_4gd9x_157:not(#\9):not(.awsui_in-filtering-token_18eso_4gd9x_220).awsui_warning_18eso_4gd9x_320:focus, .awsui_button-trigger_18eso_4gd9x_157:not(#\9):not(.awsui_in-filtering-token_18eso_4gd9x_220).awsui_warning_18eso_4gd9x_320:focus:focus {
  box-shadow: 0 0 0 var(--border-control-invalid-focus-ring-shadow-spread-fhmbjn, 2px) var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_button-trigger_18eso_4gd9x_157.awsui_inline-tokens_18eso_4gd9x_329:not(#\9) {
  padding-block: 0;
}