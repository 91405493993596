/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_root_2qdw9_vwol1_177:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  cursor: inherit;
  display: flex;
  inline-size: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.awsui_root_2qdw9_vwol1_177.awsui_refresh_2qdw9_vwol1_216:not(#\9), .awsui_root_2qdw9_vwol1_177:not(#\9):not(.awsui_root-no-actions_2qdw9_vwol1_216) {
  row-gap: var(--space-scaled-xxs-7597g1, 4px);
}
.awsui_root-no-actions_2qdw9_vwol1_216.awsui_root-variant-h2_2qdw9_vwol1_219:not(#\9):not(.awsui_refresh_2qdw9_vwol1_216), .awsui_root-no-actions_2qdw9_vwol1_216.awsui_root-variant-h3_2qdw9_vwol1_219:not(#\9):not(.awsui_refresh_2qdw9_vwol1_216), .awsui_root-has-description_2qdw9_vwol1_219.awsui_root-variant-h2_2qdw9_vwol1_219:not(#\9):not(.awsui_refresh_2qdw9_vwol1_216), .awsui_root-has-description_2qdw9_vwol1_219.awsui_root-variant-h3_2qdw9_vwol1_219:not(#\9):not(.awsui_refresh_2qdw9_vwol1_216) {
  padding-block-end: var(--space-scaled-xxs-7597g1, 4px);
}
.awsui_root-no-actions_2qdw9_vwol1_216:not(#\9):not(.awsui_root-has-description_2qdw9_vwol1_219):not(.awsui_refresh_2qdw9_vwol1_216) {
  padding-block-end: calc(var(--space-scaled-xs-26e2du, 8px) + var(--space-xxxs-zbmxqb, 2px));
}
.awsui_root-no-actions_2qdw9_vwol1_216.awsui_refresh_2qdw9_vwol1_216.awsui_root-variant-h2_2qdw9_vwol1_219:not(#\9), .awsui_root-no-actions_2qdw9_vwol1_216.awsui_refresh_2qdw9_vwol1_216.awsui_root-variant-h3_2qdw9_vwol1_219:not(#\9) {
  row-gap: var(--space-scaled-xxxs-27y4hv, 2px);
}
.awsui_root_2qdw9_vwol1_177.awsui_root-variant-h1_2qdw9_vwol1_228:not(#\9):not(.awsui_refresh_2qdw9_vwol1_216) {
  row-gap: var(--space-scaled-xxs-7597g1, 4px);
}
.awsui_root-no-actions_2qdw9_vwol1_216:not(#\9):not(.awsui_root-has-description_2qdw9_vwol1_219).awsui_refresh_2qdw9_vwol1_216.awsui_root-variant-h3_2qdw9_vwol1_219 {
  padding-block-end: var(--space-scaled-xs-26e2du, 8px);
}
.awsui_root_2qdw9_vwol1_177.awsui_root-variant-h1_2qdw9_vwol1_228.awsui_root-has-description_2qdw9_vwol1_219:not(#\9):not(.awsui_refresh_2qdw9_vwol1_216) {
  padding-block-end: var(--space-scaled-2x-xxs-7v8ivz, 4px);
}

.awsui_main_2qdw9_vwol1_238:not(#\9) {
  word-wrap: break-word;
  max-inline-size: 100%;
  display: flex;
  justify-content: space-between;
  inline-size: 100%;
  flex-wrap: wrap;
  column-gap: var(--space-xs-zb16t3, 8px);
  row-gap: calc(var(--space-scaled-xxs-7597g1, 4px) + var(--space-scaled-xxxs-27y4hv, 2px));
}
.awsui_main_2qdw9_vwol1_238.awsui_no-wrap_2qdw9_vwol1_248:not(#\9) {
  flex-wrap: nowrap;
}
.awsui_main_2qdw9_vwol1_238.awsui_refresh_2qdw9_vwol1_216:not(#\9) {
  row-gap: var(--space-xxs-p8yyaw, 4px);
}
.awsui_main-variant-h1_2qdw9_vwol1_254:not(#\9) {
  row-gap: var(--space-scaled-2x-xxs-7v8ivz, 4px);
}

.awsui_root-has-description_2qdw9_vwol1_219 > .awsui_main_2qdw9_vwol1_238:not(#\9) {
  row-gap: var(--space-scaled-2x-xxs-7v8ivz, 4px);
}

.awsui_actions_2qdw9_vwol1_262:not(#\9) {
  display: flex;
  align-items: flex-start;
  min-block-size: var(--size-vertical-input-v5iwwf, 32px);
}
.awsui_actions-centered_2qdw9_vwol1_267:not(#\9) {
  align-items: center;
}
.awsui_actions-variant-h1_2qdw9_vwol1_270.awsui_refresh_2qdw9_vwol1_216:not(#\9) {
  padding-block: calc((var(--line-height-heading-xl-avbttk, 30px) - var(--size-vertical-input-v5iwwf, 32px)) / 2);
  padding-inline: 0;
}
.awsui_actions-variant-h2_2qdw9_vwol1_274.awsui_refresh_2qdw9_vwol1_216:not(#\9) {
  padding-block: calc((var(--line-height-heading-l-mmm3my, 24px) - var(--size-vertical-input-v5iwwf, 32px)) / 2);
  padding-inline: 0;
}
.awsui_actions-variant-h3_2qdw9_vwol1_278.awsui_refresh_2qdw9_vwol1_216:not(#\9) {
  padding-block: calc((var(--line-height-heading-m-50evfk, 22px) - var(--size-vertical-input-v5iwwf, 32px)) / 2);
  padding-inline: 0;
}
.awsui_actions-variant-h1_2qdw9_vwol1_270:not(#\9):not(.awsui_refresh_2qdw9_vwol1_216) {
  padding-block: var(--space-scaled-xs-26e2du, 8px);
  padding-inline: 0;
}
.awsui_actions-variant-h2_2qdw9_vwol1_274:not(#\9):not(.awsui_refresh_2qdw9_vwol1_216), .awsui_actions-variant-h3_2qdw9_vwol1_278:not(#\9):not(.awsui_refresh_2qdw9_vwol1_216) {
  padding-block: var(--space-scaled-xxs-7597g1, 4px);
  padding-inline: 0;
}
.awsui_root-has-description_2qdw9_vwol1_219 > .awsui_main_2qdw9_vwol1_238 > .awsui_actions_2qdw9_vwol1_262:not(#\9) {
  padding-block-end: 0;
}

.awsui_title_2qdw9_vwol1_294:not(#\9) {
  min-inline-size: 0;
  word-break: break-word;
  color: var(--color-text-heading-default-6yaix2, #0f141a);
}
.awsui_title-variant-h1_2qdw9_vwol1_299:not(#\9) {
  font-size: var(--font-size-heading-xl-ypc05a, 24px);
  padding-block-start: var(--space-scaled-2x-xxs-7v8ivz, 4px);
}
.awsui_title-variant-h1_2qdw9_vwol1_299.awsui_refresh_2qdw9_vwol1_216:not(#\9) {
  padding-block-start: calc((var(--size-vertical-input-v5iwwf, 32px) - var(--line-height-heading-xl-avbttk, 30px)) / 2);
}
.awsui_title-variant-h2_2qdw9_vwol1_306:not(#\9) {
  font-size: var(--font-size-heading-l-ou0d0l, 20px);
}
.awsui_title-variant-h2_2qdw9_vwol1_306.awsui_refresh_2qdw9_vwol1_216:not(#\9) {
  padding-block-start: calc((var(--size-vertical-input-v5iwwf, 32px) - var(--line-height-heading-l-mmm3my, 24px)) / 2);
}
.awsui_title-variant-h3_2qdw9_vwol1_312:not(#\9) {
  font-size: var(--font-size-heading-m-sjd256, 18px);
}
.awsui_title-variant-h3_2qdw9_vwol1_312.awsui_refresh_2qdw9_vwol1_216:not(#\9) {
  padding-block-start: calc((var(--size-vertical-input-v5iwwf, 32px) - var(--line-height-heading-m-50evfk, 22px)) / 2);
}
.awsui_title-variant-h2_2qdw9_vwol1_306:not(#\9):not(.awsui_refresh_2qdw9_vwol1_216), .awsui_title-variant-h3_2qdw9_vwol1_312:not(#\9):not(.awsui_refresh_2qdw9_vwol1_216) {
  padding-block-start: var(--space-scaled-xs-26e2du, 8px);
}
.awsui_no-wrap_2qdw9_vwol1_248 > .awsui_title_2qdw9_vwol1_294:not(#\9):not(.awsui_refresh_2qdw9_vwol1_216) {
  padding-block-end: calc(var(--space-scaled-xxs-7597g1, 4px) + var(--space-scaled-xxxs-27y4hv, 2px));
}

.awsui_root-no-actions_2qdw9_vwol1_216.awsui_root-has-description_2qdw9_vwol1_219 > .awsui_main_2qdw9_vwol1_238 > :not(#\9):not(.awsui_refresh_2qdw9_vwol1_216).awsui_title-variant-h2_2qdw9_vwol1_306, .awsui_root-no-actions_2qdw9_vwol1_216.awsui_root-has-description_2qdw9_vwol1_219 > .awsui_main_2qdw9_vwol1_238 > :not(#\9):not(.awsui_refresh_2qdw9_vwol1_216).awsui_title-variant-h3_2qdw9_vwol1_312 {
  padding-block-end: var(--space-scaled-xxs-7597g1, 4px);
}

.awsui_root-variant-h1_2qdw9_vwol1_228.awsui_root-no-actions_2qdw9_vwol1_216:not(#\9):not(.awsui_root-has-description_2qdw9_vwol1_219):not(.awsui_refresh_2qdw9_vwol1_216) {
  padding-block-end: var(--space-scaled-2x-xxs-7v8ivz, 4px);
}

.awsui_virtual-space_2qdw9_vwol1_333:not(#\9) {
  position: absolute !important;
  inset-block-start: -9999px !important;
  inset-inline-start: -9999px !important;
}

.awsui_info_2qdw9_vwol1_339:not(#\9) {
  padding-inline-end: var(--space-s-34lx8l, 12px);
}

.awsui_description_2qdw9_vwol1_343:not(#\9) {
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;
  color: var(--color-text-heading-secondary-0myrp3, #424650);
}
.awsui_description-variant-h1_2qdw9_vwol1_350:not(#\9) {
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
}
.awsui_description-variant-h2_2qdw9_vwol1_354:not(#\9) {
  font-size: var(--font-header-h2-description-size-2hj97p, 14px);
  line-height: var(--font-header-h2-description-line-height-rtewek, 20px);
}
.awsui_description-variant-h2_2qdw9_vwol1_354:not(#\9):not(.awsui_refresh_2qdw9_vwol1_216) {
  padding-block-end: var(--space-scaled-xxs-7597g1, 4px);
}
.awsui_description-variant-h3_2qdw9_vwol1_361:not(#\9) {
  font-size: var(--font-size-body-s-asqx2i, 12px);
  line-height: var(--line-height-body-s-7zv1j5, 16px);
  letter-spacing: var(--letter-spacing-body-s-z9jkwp, 0.005em);
}
.awsui_description-variant-h3_2qdw9_vwol1_361:not(#\9):not(.awsui_refresh_2qdw9_vwol1_216) {
  padding-block-end: var(--space-scaled-xxs-7597g1, 4px);
}

.awsui_heading_2qdw9_vwol1_370:not(#\9) {
  margin-block: 0;
  margin-inline: 0;
  display: inline;
  font-size: inherit;
  margin-inline-end: var(--space-xs-zb16t3, 8px);
}
.awsui_heading_2qdw9_vwol1_370:not(#\9):only-child {
  margin-block: 0;
  margin-inline: 0;
}
.awsui_heading-variant-h1_2qdw9_vwol1_381:not(#\9) {
  font-size: var(--font-size-heading-xl-ypc05a, 24px);
  line-height: var(--line-height-heading-xl-avbttk, 30px);
  letter-spacing: var(--letter-spacing-heading-xl-sagq9c, -0.02em);
}
.awsui_heading-variant-h2_2qdw9_vwol1_386:not(#\9) {
  font-size: var(--font-size-heading-l-ou0d0l, 20px);
  line-height: var(--line-height-heading-l-mmm3my, 24px);
  letter-spacing: var(--letter-spacing-heading-l-qqjd4y, -0.015em);
}
.awsui_heading-variant-h3_2qdw9_vwol1_391:not(#\9) {
  font-size: var(--font-size-heading-m-sjd256, 18px);
  line-height: var(--line-height-heading-m-50evfk, 22px);
  letter-spacing: var(--letter-spacing-heading-m-0w575i, -0.01em);
}

.awsui_heading-text_2qdw9_vwol1_397:not(#\9) {
  /* used in test-utils */
}
.awsui_heading-text-variant-h1_2qdw9_vwol1_400:not(#\9) {
  font-size: var(--font-size-heading-xl-ypc05a, 24px);
  line-height: var(--line-height-heading-xl-avbttk, 30px);
  letter-spacing: var(--letter-spacing-heading-xl-sagq9c, -0.02em);
  font-weight: var(--font-weight-heading-xl-yvsksd, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
}
.awsui_heading-text-variant-h2_2qdw9_vwol1_408:not(#\9) {
  font-size: var(--font-size-heading-l-ou0d0l, 20px);
  line-height: var(--line-height-heading-l-mmm3my, 24px);
  letter-spacing: var(--letter-spacing-heading-l-qqjd4y, -0.015em);
  font-weight: var(--font-weight-heading-l-f8711v, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
}
.awsui_heading-text-variant-h3_2qdw9_vwol1_416:not(#\9) {
  font-size: var(--font-size-heading-m-sjd256, 18px);
  line-height: var(--line-height-heading-m-50evfk, 22px);
  letter-spacing: var(--letter-spacing-heading-m-0w575i, -0.01em);
  font-weight: var(--font-weight-heading-m-m2ekmb, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
}

.awsui_counter_2qdw9_vwol1_425:not(#\9) {
  color: var(--color-text-counter-goqopo, #656871);
  font-weight: 400;
}