/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_link_1kosq_1tkru_141:not(#\9):after {
  display: none;
}

.awsui_breadcrumb_1kosq_1tkru_145:not(#\9),
.awsui_ghost-breadcrumb_1kosq_1tkru_146:not(#\9) {
  display: flex;
}
.awsui_breadcrumb_1kosq_1tkru_145 > .awsui_icon_1kosq_1tkru_149:not(#\9),
.awsui_ghost-breadcrumb_1kosq_1tkru_146 > .awsui_icon_1kosq_1tkru_149:not(#\9) {
  margin-block: 0;
  margin-inline: var(--space-xs-zb16t3, 8px);
  color: var(--color-text-breadcrumb-icon-u5luu4, #8c8c94);
}
.awsui_breadcrumb_1kosq_1tkru_145 > .awsui_anchor_1kosq_1tkru_155:not(#\9),
.awsui_ghost-breadcrumb_1kosq_1tkru_146 > .awsui_anchor_1kosq_1tkru_155:not(#\9) {
  min-inline-size: 0;
  overflow: hidden;
  text-underline-offset: 0.25em;
  text-decoration-thickness: 1px;
  color: var(--color-text-link-default-enwvrt, #006ce0);
  font-weight: inherit;
  letter-spacing: normal;
  text-decoration-line: underline;
  text-decoration-color: currentColor;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-nf6485, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_breadcrumb_1kosq_1tkru_145 > .awsui_anchor_1kosq_1tkru_155:not(#\9),
  .awsui_ghost-breadcrumb_1kosq_1tkru_146 > .awsui_anchor_1kosq_1tkru_155:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_breadcrumb_1kosq_1tkru_145 > .awsui_anchor_1kosq_1tkru_155:not(#\9), .awsui-mode-entering .awsui_breadcrumb_1kosq_1tkru_145 > .awsui_anchor_1kosq_1tkru_155:not(#\9), .awsui-motion-disabled .awsui_ghost-breadcrumb_1kosq_1tkru_146 > .awsui_anchor_1kosq_1tkru_155:not(#\9), .awsui-mode-entering .awsui_ghost-breadcrumb_1kosq_1tkru_146 > .awsui_anchor_1kosq_1tkru_155:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_breadcrumb_1kosq_1tkru_145 > .awsui_anchor_1kosq_1tkru_155:not(#\9):hover,
.awsui_ghost-breadcrumb_1kosq_1tkru_146 > .awsui_anchor_1kosq_1tkru_155:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_breadcrumb_1kosq_1tkru_145 > .awsui_anchor_1kosq_1tkru_155:not(#\9):focus,
.awsui_ghost-breadcrumb_1kosq_1tkru_146 > .awsui_anchor_1kosq_1tkru_155:not(#\9):focus {
  outline: none;
}
.awsui_breadcrumb_1kosq_1tkru_145 > .awsui_anchor_1kosq_1tkru_155:not(#\9):active,
.awsui_ghost-breadcrumb_1kosq_1tkru_146 > .awsui_anchor_1kosq_1tkru_155:not(#\9):active {
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_breadcrumb_1kosq_1tkru_145 > .awsui_anchor_1kosq_1tkru_155:not(#\9):active, .awsui_breadcrumb_1kosq_1tkru_145 > .awsui_anchor_1kosq_1tkru_155:not(#\9):focus, .awsui_breadcrumb_1kosq_1tkru_145 > .awsui_anchor_1kosq_1tkru_155:not(#\9):hover, .awsui_ghost-breadcrumb_1kosq_1tkru_146 > .awsui_anchor_1kosq_1tkru_155:not(#\9):active, .awsui_ghost-breadcrumb_1kosq_1tkru_146 > .awsui_anchor_1kosq_1tkru_155:not(#\9):focus, .awsui_ghost-breadcrumb_1kosq_1tkru_146 > .awsui_anchor_1kosq_1tkru_155:not(#\9):hover {
  text-decoration-line: underline;
  text-decoration-color: currentColor;
}
.awsui_breadcrumb_1kosq_1tkru_145 > .awsui_anchor_1kosq_1tkru_155 > .awsui_text_1kosq_1tkru_202:not(#\9),
.awsui_ghost-breadcrumb_1kosq_1tkru_146 > .awsui_anchor_1kosq_1tkru_155 > .awsui_text_1kosq_1tkru_202:not(#\9) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
body[data-awsui-focus-visible=true] .awsui_breadcrumb_1kosq_1tkru_145 > .awsui_anchor_1kosq_1tkru_155:not(#\9):focus,
body[data-awsui-focus-visible=true] .awsui_ghost-breadcrumb_1kosq_1tkru_146 > .awsui_anchor_1kosq_1tkru_155:not(#\9):focus {
  outline: thin dotted;
  outline: var(--border-link-focus-ring-outline-kkfop6, 0);
  outline-offset: 2px;
  outline-color: var(--color-border-item-focused-nv6mhz, #006ce0);
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 var(--border-link-focus-ring-shadow-spread-v8hkrl, 2px) var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_breadcrumb_1kosq_1tkru_145.awsui_last_1kosq_1tkru_221 > .awsui_icon_1kosq_1tkru_149:not(#\9),
.awsui_ghost-breadcrumb_1kosq_1tkru_146.awsui_last_1kosq_1tkru_221 > .awsui_icon_1kosq_1tkru_149:not(#\9) {
  display: none;
}
.awsui_breadcrumb_1kosq_1tkru_145.awsui_last_1kosq_1tkru_221 > .awsui_anchor_1kosq_1tkru_155:not(#\9),
.awsui_ghost-breadcrumb_1kosq_1tkru_146.awsui_last_1kosq_1tkru_221 > .awsui_anchor_1kosq_1tkru_155:not(#\9) {
  color: var(--color-text-breadcrumb-current-9h12sf, #656871);
  font-weight: 700;
  text-decoration: none;
  cursor: default;
}