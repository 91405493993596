/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_dm8gx_61krk_157:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}
.awsui_root_dm8gx_61krk_157.awsui_has-items_dm8gx_61krk_191:not(#\9):not(.awsui_no-padding_dm8gx_61krk_191) {
  padding-block-start: var(--space-xs-zb16t3, 8px);
}

.awsui_dismiss-button_dm8gx_61krk_195:not(#\9) {
  margin-block-start: -1px;
  margin-block-end: 0;
  margin-inline-start: var(--space-xxs-p8yyaw, 4px);
  margin-inline-end: -1px;
  border-block: 1px solid transparent;
  border-inline: 1px solid transparent;
  padding-block: 0;
  padding-inline: var(--space-xxs-p8yyaw, 4px);
  color: var(--color-text-button-inline-icon-default-p881yb, #006ce0);
  background-color: transparent;
}
body[data-awsui-focus-visible=true] .awsui_dismiss-button_dm8gx_61krk_195:not(#\9):focus {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_dismiss-button_dm8gx_61krk_195:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(0px - 1px);
}
body[data-awsui-focus-visible=true] .awsui_dismiss-button_dm8gx_61krk_195:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * 0px);
  inset-block-start: calc(-1 * 0px);
  inline-size: calc(100% + 0px + 0px);
  block-size: calc(100% + 0px + 0px);
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_dismiss-button_dm8gx_61krk_195:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
.awsui_dismiss-button_dm8gx_61krk_195:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-button-inline-icon-hover-vcdou1, #002b66);
}

.awsui_token_dm8gx_61krk_237:not(#\9) {
  block-size: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-xxs-p8yyaw, 4px);
}

.awsui_token-box_dm8gx_61krk_244:not(#\9) {
  position: relative;
  block-size: 100%;
  border-block: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-item-selected-m769ej, #006ce0);
  border-inline: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-item-selected-m769ej, #006ce0);
  padding-block-start: var(--space-scaled-xxs-7597g1, 4px);
  padding-block-end: var(--space-scaled-xxs-7597g1, 4px);
  padding-inline-start: var(--space-field-horizontal-gg19kw, 12px);
  padding-inline-end: var(--space-xxs-p8yyaw, 4px);
  display: flex;
  align-items: flex-start;
  background: var(--color-background-item-selected-njak80, #f0fbff);
  border-start-start-radius: var(--border-radius-token-923jqq, 8px);
  border-start-end-radius: var(--border-radius-token-923jqq, 8px);
  border-end-start-radius: var(--border-radius-token-923jqq, 8px);
  border-end-end-radius: var(--border-radius-token-923jqq, 8px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  box-sizing: border-box;
}

.awsui_token-box-readonly_dm8gx_61krk_264:not(#\9) {
  border-color: var(--color-border-input-disabled-lb7zn3, #ebebf0);
  background-color: var(--color-background-container-content-epgqkc, #ffffff);
  pointer-events: none;
}
.awsui_token-box-readonly_dm8gx_61krk_264 > .awsui_dismiss-button_dm8gx_61krk_195:not(#\9) {
  color: var(--color-text-button-inline-icon-disabled-yk9lvs, #b4b4bb);
}
.awsui_token-box-readonly_dm8gx_61krk_264 > .awsui_dismiss-button_dm8gx_61krk_195:not(#\9):hover {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  cursor: initial;
  color: var(--color-text-button-inline-icon-disabled-yk9lvs, #b4b4bb);
}

.awsui_token-box-disabled_dm8gx_61krk_278.awsui_token-box-disabled_dm8gx_61krk_278:not(#\9) {
  border-color: var(--color-border-control-disabled-qc5csz, #dedee3);
  background-color: var(--color-background-container-content-epgqkc, #ffffff);
  color: var(--color-text-disabled-e4vsj4, #b4b4bb);
  pointer-events: none;
}
.awsui_token-box-disabled_dm8gx_61krk_278.awsui_token-box-disabled_dm8gx_61krk_278 > .awsui_dismiss-button_dm8gx_61krk_195:not(#\9) {
  color: var(--color-text-button-inline-icon-disabled-yk9lvs, #b4b4bb);
}
.awsui_token-box-disabled_dm8gx_61krk_278.awsui_token-box-disabled_dm8gx_61krk_278 > .awsui_dismiss-button_dm8gx_61krk_195:not(#\9):hover {
  cursor: initial;
  color: var(--color-text-button-inline-icon-disabled-yk9lvs, #b4b4bb);
}