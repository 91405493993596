/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_placeholder_dwuol_1sd76_141:not(#\9) {
  color: var(--color-text-input-placeholder-f3x213, #656871);
  font-style: italic;
}

.awsui_item_dwuol_1sd76_146:not(#\9) {
  display: flex;
  align-items: center;
}
.awsui_item_dwuol_1sd76_146 > .awsui_checkbox_dwuol_1sd76_150:not(#\9) {
  position: relative;
  min-block-size: var(--size-control-28i7hr, 16px);
  min-inline-size: var(--size-control-28i7hr, 16px);
  block-size: var(--size-control-28i7hr, 16px);
  inline-size: var(--size-control-28i7hr, 16px);
  margin-inline-end: var(--space-field-horizontal-gg19kw, 12px);
}

.awsui_filter_dwuol_1sd76_159:not(#\9) {
  z-index: 4;
  flex-shrink: 0;
}

.awsui_trigger_dwuol_1sd76_164:not(#\9) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.awsui_layout-strut_dwuol_1sd76_170:not(#\9) {
  inline-size: 100%;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.awsui_list-bottom_dwuol_1sd76_176:not(#\9) {
  /* used in unit-tests */
}

.awsui_selected-icon_dwuol_1sd76_180:not(#\9) {
  color: var(--color-border-item-focused-nv6mhz, #006ce0);
}

.awsui_show-label-tag_dwuol_1sd76_184 > .awsui_selected-icon_dwuol_1sd76_180:not(#\9) {
  padding-inline-start: var(--space-scaled-s-aqzyko, 12px);
}

.awsui_inline-token-trigger_dwuol_1sd76_188:not(#\9) {
  display: flex;
  flex-wrap: nowrap;
  column-gap: var(--space-xxs-p8yyaw, 4px);
  inline-size: 100%;
}

.awsui_inline-token-list_dwuol_1sd76_195:not(#\9) {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: var(--space-xxs-p8yyaw, 4px);
  inline-size: 100%;
  overflow-x: hidden;
  mask-image: linear-gradient(270deg, transparent, white 20px, white);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}
.awsui_inline-token-list_dwuol_1sd76_195:not(#\9):dir(rtl) {
  mask-image: linear-gradient(-270deg, transparent, white 20px, white);
}
.awsui_inline-token-list_dwuol_1sd76_195 > .awsui_inline-token_dwuol_1sd76_188:not(#\9) {
  display: flex;
  align-items: center;
  min-inline-size: max-content;
  block-size: 18px;
  border-block: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-item-selected-m769ej, #006ce0);
  border-inline: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-item-selected-m769ej, #006ce0);
  padding-block: 0;
  padding-inline: var(--space-xxs-p8yyaw, 4px);
  background: var(--color-background-item-selected-njak80, #f0fbff);
  border-start-start-radius: var(--border-radius-token-923jqq, 8px);
  border-start-end-radius: var(--border-radius-token-923jqq, 8px);
  border-end-start-radius: var(--border-radius-token-923jqq, 8px);
  border-end-end-radius: var(--border-radius-token-923jqq, 8px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
}

.awsui_visual-refresh_dwuol_1sd76_225 > .awsui_inline-token-list_dwuol_1sd76_195 > .awsui_inline-token_dwuol_1sd76_188:not(#\9) {
  border-start-start-radius: var(--border-radius-badge-0z09dt, 4px);
  border-start-end-radius: var(--border-radius-badge-0z09dt, 4px);
  border-end-start-radius: var(--border-radius-badge-0z09dt, 4px);
  border-end-end-radius: var(--border-radius-badge-0z09dt, 4px);
}

.awsui_inline-token-hidden-placeholder_dwuol_1sd76_232:not(#\9) {
  position: absolute !important;
  inset-block-start: -9999px !important;
  inset-inline-start: -9999px !important;
}

.awsui_inline-token-counter_dwuol_1sd76_238:not(#\9) {
  white-space: nowrap;
}

.awsui_inline-token-trigger--disabled_dwuol_1sd76_242 > .awsui_inline-token-list_dwuol_1sd76_195 > .awsui_inline-token_dwuol_1sd76_188:not(#\9) {
  border-color: var(--color-border-control-disabled-qc5csz, #dedee3);
  background-color: var(--color-background-container-content-epgqkc, #ffffff);
  color: var(--color-text-disabled-e4vsj4, #b4b4bb);
}

.awsui_inline-label-trigger-wrapper_dwuol_1sd76_248:not(#\9) {
  margin-block-start: -7px;
}

.awsui_inline-label-wrapper_dwuol_1sd76_252:not(#\9) {
  margin-block-start: calc(var(--space-scaled-xs-26e2du, 8px) * -1);
}

.awsui_inline-label_dwuol_1sd76_248:not(#\9) {
  background-image: linear-gradient(to bottom, transparent calc(100% - (var(--border-width-field-h1g7tw, 2px) + var(--border-control-focus-ring-shadow-spread-0ctpjf, 0px) + 5px)), var(--color-background-input-default-k0a6tw, #ffffff) 1px);
  background-position: bottom;
  box-sizing: border-box;
  display: inline-block;
  color: var(--color-text-form-label-5nbxa6, #0f141a);
  font-weight: var(--font-display-label-weight-815ja9, 700);
  font-size: var(--font-size-body-s-asqx2i, 12px);
  line-height: 14px;
  letter-spacing: var(--letter-spacing-body-s-z9jkwp, 0.005em);
  position: relative;
  inset-inline-start: calc(var(--border-width-field-h1g7tw, 2px) + var(--space-field-horizontal-gg19kw, 12px) - var(--space-scaled-xxs-7597g1, 4px));
  margin-block-start: var(--space-scaled-xs-26e2du, 8px);
  padding-block-end: 2px;
  padding-inline: var(--space-scaled-xxs-7597g1, 4px);
  max-inline-size: calc(100% - 2 * var(--space-field-horizontal-gg19kw, 12px));
  z-index: 1;
}
.awsui_inline-label_dwuol_1sd76_248.awsui_inline-label-disabled_dwuol_1sd76_274:not(#\9) {
  background: var(--color-background-container-header-clzg6q, #ffffff);
  border-start-start-radius: 2px;
  border-start-end-radius: 2px;
  border-end-start-radius: 2px;
  border-end-end-radius: 2px;
}

.awsui_disabled-reason-tooltip_dwuol_1sd76_282:not(#\9) {
  /* used in test-utils or tests */
}