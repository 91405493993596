/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_14iqq_16c34_185:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  word-wrap: break-word;
  position: relative;
}
.awsui_root_14iqq_16c34_185.awsui_fit-height_14iqq_16c34_221:not(#\9) {
  display: flex;
  flex-direction: column;
  block-size: 100%;
}
.awsui_root_14iqq_16c34_185.awsui_fit-height_14iqq_16c34_221.awsui_with-side-media_14iqq_16c34_226:not(#\9) {
  flex-direction: row;
}
.awsui_root_14iqq_16c34_185.awsui_variant-default_14iqq_16c34_229:not(#\9), .awsui_root_14iqq_16c34_185.awsui_variant-stacked_14iqq_16c34_229:not(#\9) {
  background-color: var(--color-background-container-content-epgqkc, #ffffff);
  border-start-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-start-end-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-end-radius: var(--border-radius-container-wqv1zi, 16px);
  box-sizing: border-box;
}
.awsui_root_14iqq_16c34_185.awsui_variant-default_14iqq_16c34_229.awsui_refresh_14iqq_16c34_237:not(#\9), .awsui_root_14iqq_16c34_185.awsui_variant-stacked_14iqq_16c34_229.awsui_refresh_14iqq_16c34_237:not(#\9) {
  border-block: solid var(--border-divider-section-width-1061zr, 1px) var(--color-border-divider-default-cx07f2, #c6c6cd);
  border-inline: solid var(--border-divider-section-width-1061zr, 1px) var(--color-border-divider-default-cx07f2, #c6c6cd);
}
.awsui_root_14iqq_16c34_185.awsui_variant-default_14iqq_16c34_229:not(#\9):not(.awsui_refresh_14iqq_16c34_237)::before, .awsui_root_14iqq_16c34_185.awsui_variant-stacked_14iqq_16c34_229:not(#\9):not(.awsui_refresh_14iqq_16c34_237)::before {
  content: "";
  position: absolute;
  inset-inline-start: 0px;
  inset-block-start: 0px;
  inline-size: 100%;
  block-size: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  border-color: transparent;
  border-block-start: var(--border-container-top-width-uugwwl, 0px) solid var(--color-border-container-top-736buh, transparent);
  border-start-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-start-end-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-end-radius: var(--border-radius-container-wqv1zi, 16px);
  z-index: 1;
}
.awsui_root_14iqq_16c34_185.awsui_variant-default_14iqq_16c34_229:not(#\9):not(.awsui_refresh_14iqq_16c34_237)::after, .awsui_root_14iqq_16c34_185.awsui_variant-stacked_14iqq_16c34_229:not(#\9):not(.awsui_refresh_14iqq_16c34_237)::after {
  content: "";
  position: absolute;
  inset-inline-start: 0px;
  inset-block-start: 0px;
  inline-size: 100%;
  block-size: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  border-start-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-start-end-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-end-radius: var(--border-radius-container-wqv1zi, 16px);
  box-shadow: var(--shadow-container-fxmdn3, 0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12));
}
.awsui_root_14iqq_16c34_185.awsui_variant-stacked_14iqq_16c34_229:not(#\9):not(:last-child), .awsui_root_14iqq_16c34_185.awsui_variant-stacked_14iqq_16c34_229:not(#\9):not(:last-child)::before, .awsui_root_14iqq_16c34_185.awsui_variant-stacked_14iqq_16c34_229:not(#\9):not(:last-child)::after {
  border-end-end-radius: 0;
  border-end-start-radius: 0;
  border-block-end-width: 0;
}
.awsui_root_14iqq_16c34_185.awsui_variant-stacked_14iqq_16c34_229 + .awsui_root_14iqq_16c34_185.awsui_variant-stacked_14iqq_16c34_229:not(#\9), .awsui_root_14iqq_16c34_185.awsui_variant-stacked_14iqq_16c34_229 + .awsui_root_14iqq_16c34_185.awsui_variant-stacked_14iqq_16c34_229:not(#\9)::before, .awsui_root_14iqq_16c34_185.awsui_variant-stacked_14iqq_16c34_229 + .awsui_root_14iqq_16c34_185.awsui_variant-stacked_14iqq_16c34_229:not(#\9)::after {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}
.awsui_root_14iqq_16c34_185.awsui_variant-stacked_14iqq_16c34_229 + .awsui_root_14iqq_16c34_185.awsui_variant-stacked_14iqq_16c34_229:not(#\9):not(.awsui_refresh_14iqq_16c34_237)::before {
  border-block-start: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
}
.awsui_root_14iqq_16c34_185.awsui_sticky-enabled_14iqq_16c34_287:not(#\9):not(.awsui_refresh_14iqq_16c34_237)::before {
  inset-block-start: calc(-1 * var(--border-container-top-width-uugwwl, 0px));
}
.awsui_root_14iqq_16c34_185.awsui_sticky-enabled_14iqq_16c34_287:not(#\9):not(.awsui_refresh_14iqq_16c34_237).awsui_variant-stacked_14iqq_16c34_229::before {
  inset-block-start: calc(-1 * var(--border-divider-section-width-1061zr, 1px));
}

.awsui_with-side-media_14iqq_16c34_226:not(#\9) {
  display: flex;
  flex-direction: row;
}

.awsui_with-top-media_14iqq_16c34_299:not(#\9) {
  display: flex;
  flex-direction: column;
}

.awsui_content-wrapper_14iqq_16c34_304:not(#\9) {
  display: flex;
  flex-direction: column;
  inline-size: 100%;
}
.awsui_content-wrapper-fit-height_14iqq_16c34_309:not(#\9) {
  block-size: 100%;
  overflow: hidden;
  border-end-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-end-radius: var(--border-radius-container-wqv1zi, 16px);
}

.awsui_media_14iqq_16c34_316:not(#\9) {
  overflow: hidden;
  flex-shrink: 0;
}
.awsui_media_14iqq_16c34_316 img:not(#\9),
.awsui_media_14iqq_16c34_316 video:not(#\9),
.awsui_media_14iqq_16c34_316 picture:not(#\9) {
  inline-size: 100%;
  block-size: 100%;
  object-fit: cover;
  object-position: center;
}
.awsui_media_14iqq_16c34_316 iframe:not(#\9) {
  inline-size: 100%;
  block-size: 100%;
  border-block: 0;
  border-inline: 0;
}
.awsui_media-top_14iqq_16c34_334:not(#\9) {
  max-block-size: 66%;
  border-start-start-radius: calc(var(--border-radius-container-wqv1zi, 16px) - 1px);
  border-start-end-radius: calc(var(--border-radius-container-wqv1zi, 16px) - 1px);
}
.awsui_media-side_14iqq_16c34_339:not(#\9) {
  max-inline-size: 66%;
  border-start-start-radius: calc(var(--border-radius-container-wqv1zi, 16px) - 1px);
  border-end-start-radius: calc(var(--border-radius-container-wqv1zi, 16px) - 1px);
}

.awsui_header_14iqq_16c34_345:not(#\9) {
  background-color: var(--color-background-container-header-clzg6q, #ffffff);
  border-start-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-start-end-radius: var(--border-radius-container-wqv1zi, 16px);
}
.awsui_header_14iqq_16c34_345.awsui_header-full-page_14iqq_16c34_350:not(#\9) {
  background-color: var(--color-background-layout-main-cp7lkl, #ffffff);
}
.awsui_header_14iqq_16c34_345.awsui_header-with-media_14iqq_16c34_353:not(#\9) {
  background: none;
}
.awsui_header_14iqq_16c34_345.awsui_header-with-media_14iqq_16c34_353:not(#\9):not(:empty) {
  border-block-end: none;
}
.awsui_header-sticky-disabled_14iqq_16c34_359:not(#\9) {
  position: relative;
  z-index: 1;
}
.awsui_header-sticky-enabled_14iqq_16c34_363:not(#\9) {
  inset-block-start: 0;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  position: sticky;
  z-index: 800;
}
.awsui_header-stuck_14iqq_16c34_369:not(#\9) {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}
.awsui_header-stuck_14iqq_16c34_369:not(#\9)::before {
  border-block: 0;
  border-inline: 0;
}
.awsui_header-stuck_14iqq_16c34_369:not(#\9):not(.awsui_header-variant-cards_14iqq_16c34_379) {
  box-shadow: var(--shadow-sticky-embedded-uvh5ry, 0px 2px 0px 0px #e9ebed, 0px 16px 16px -12px rgba(0, 7, 22, 0.1));
}
.awsui_header-dynamic-height_14iqq_16c34_382.awsui_header-stuck_14iqq_16c34_369:not(#\9) {
  margin-block-end: calc(var(--line-height-heading-xl-avbttk, 30px) - var(--line-height-heading-l-mmm3my, 24px));
}
.awsui_header_14iqq_16c34_345:not(#\9):not(:empty) {
  border-block-end: var(--border-container-sticky-width-6asz33, 0px) solid var(--color-border-container-divider-p2uygo, transparent);
}
.awsui_header_14iqq_16c34_345.awsui_with-paddings_14iqq_16c34_388:not(#\9) {
  padding-block-start: var(--space-container-header-top-5qav00, 12px);
  padding-block-end: var(--space-container-header-bottom-vj01hn, 8px);
  padding-inline: var(--space-container-horizontal-wfukh3, 20px);
}
.awsui_header_14iqq_16c34_345.awsui_with-paddings_14iqq_16c34_388.awsui_header-variant-cards_14iqq_16c34_379:not(#\9) {
  padding-block: var(--space-container-header-top-5qav00, 12px);
  padding-inline: var(--space-container-horizontal-wfukh3, 20px);
}
.awsui_header_14iqq_16c34_345.awsui_with-hidden-content_14iqq_16c34_397:not(#\9) {
  border-end-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-end-radius: var(--border-radius-container-wqv1zi, 16px);
}
.awsui_header-variant-cards_14iqq_16c34_379:not(#\9) {
  border-start-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-start-end-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-end-radius: var(--border-radius-container-wqv1zi, 16px);
  box-sizing: border-box;
}
.awsui_header-variant-cards_14iqq_16c34_379.awsui_refresh_14iqq_16c34_237:not(#\9) {
  border-block: solid var(--border-divider-section-width-1061zr, 1px) var(--color-border-divider-default-cx07f2, #c6c6cd);
  border-inline: solid var(--border-divider-section-width-1061zr, 1px) var(--color-border-divider-default-cx07f2, #c6c6cd);
}
.awsui_header-variant-cards_14iqq_16c34_379:not(#\9):not(.awsui_refresh_14iqq_16c34_237)::before {
  content: "";
  position: absolute;
  inset-inline-start: 0px;
  inset-block-start: 0px;
  inline-size: 100%;
  block-size: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  border-color: transparent;
  border-block-start: var(--border-container-top-width-uugwwl, 0px) solid var(--color-border-container-top-736buh, transparent);
  border-start-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-start-end-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-end-radius: var(--border-radius-container-wqv1zi, 16px);
  z-index: 1;
}
.awsui_header-variant-cards_14iqq_16c34_379:not(#\9):not(.awsui_refresh_14iqq_16c34_237)::after {
  content: "";
  position: absolute;
  inset-inline-start: 0px;
  inset-block-start: 0px;
  inline-size: 100%;
  block-size: 100%;
  pointer-events: none;
  background: transparent;
  box-sizing: border-box;
  border-start-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-start-end-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-start-radius: var(--border-radius-container-wqv1zi, 16px);
  border-end-end-radius: var(--border-radius-container-wqv1zi, 16px);
  box-shadow: var(--shadow-container-fxmdn3, 0px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12));
}
.awsui_header-variant-cards_14iqq_16c34_379:not(#\9):not(.awsui_header-sticky-enabled_14iqq_16c34_363) {
  position: relative;
}
.awsui_header-variant-cards_14iqq_16c34_379.awsui_header-stuck_14iqq_16c34_369:not(#\9)::after, .awsui_header-variant-cards_14iqq_16c34_379.awsui_header-stuck_14iqq_16c34_369:not(#\9)::before {
  border-block: 0;
  border-inline: 0;
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}
.awsui_header-variant-full-page_14iqq_16c34_455.awsui_header-stuck_14iqq_16c34_369:not(#\9) {
  box-shadow: none;
}
.awsui_header-variant-full-page_14iqq_16c34_455.awsui_header-stuck_14iqq_16c34_369 > .awsui_header-cover_14iqq_16c34_458:not(#\9) {
  background-color: var(--color-background-layout-main-cp7lkl, #ffffff);
  inline-size: 100%;
  position: absolute;
  block-size: var(--space-scaled-s-aqzyko, 12px);
  inset-block-start: calc(-1 * var(--space-scaled-s-aqzyko, 12px));
}
.awsui_header-variant-full-page_14iqq_16c34_455.awsui_header-stuck_14iqq_16c34_369:not(#\9)::before {
  content: "";
  position: absolute;
  pointer-events: none;
  inset-inline-end: 0;
  inset-inline-start: 0;
  inset-block-end: 0;
  inset-block-start: 0;
  border-block-end: solid var(--border-divider-section-width-1061zr, 1px) var(--color-border-divider-default-cx07f2, #c6c6cd);
}
.awsui_header-variant-full-page_14iqq_16c34_455.awsui_header-stuck_14iqq_16c34_369:not(#\9)::after {
  content: "";
  position: absolute;
  inset: 0;
  box-shadow: var(--shadow-sticky-blixp9, 0px 4px 8px 1px rgba(0, 7, 22, 0.1));
  clip-path: polygon(-999% 100%, 999% 100%, 999% 999%, -999% 999%);
}

.awsui_content_14iqq_16c34_304:not(#\9) {
  flex: 1;
}
.awsui_content-fit-height_14iqq_16c34_486:not(#\9) {
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.awsui_content-inner_14iqq_16c34_492:not(#\9) {
  flex: 1;
}
.awsui_content-inner_14iqq_16c34_492.awsui_with-paddings_14iqq_16c34_388:not(#\9) {
  padding-block: var(--space-scaled-l-0hpmd7, 20px);
  padding-inline: var(--space-container-horizontal-wfukh3, 20px);
}
.awsui_content-inner_14iqq_16c34_492.awsui_with-paddings_14iqq_16c34_388.awsui_with-header_14iqq_16c34_499:not(#\9) {
  padding-block-start: var(--space-container-content-top-1ae0r3, 4px);
}

.awsui_footer_14iqq_16c34_503.awsui_with-paddings_14iqq_16c34_388:not(#\9) {
  padding-block: var(--space-scaled-s-aqzyko, 12px);
  padding-inline: var(--space-container-horizontal-wfukh3, 20px);
}
.awsui_footer_14iqq_16c34_503.awsui_with-divider_14iqq_16c34_507:not(#\9) {
  border-block-start: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
}