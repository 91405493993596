/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_toggle_1uo6m_dgeyf_189:not(#\9) {
  box-sizing: border-box;
  padding-block: var(--space-xxs-p8yyaw, 4px);
  padding-inline: 7px;
}

.awsui_drawer-triggers_1uo6m_dgeyf_195:not(#\9) {
  box-sizing: border-box;
}

.awsui_drawer_1uo6m_dgeyf_195:not(#\9) {
  flex-shrink: 0;
  position: relative;
  word-wrap: break-word;
  box-shadow: var(--shadow-panel-g6fkp1, 0px 0px 0px 1px #b6bec9);
}
.awsui_drawer_1uo6m_dgeyf_195:not(#\9):not(.awsui_drawer-mobile_1uo6m_dgeyf_205) {
  z-index: 830;
}
.awsui_drawer-closed_1uo6m_dgeyf_208:not(#\9) {
  min-inline-size: 40px;
}
.awsui_drawer-closed_1uo6m_dgeyf_208.awsui_drawer-mobile_1uo6m_dgeyf_205:not(#\9) {
  display: none;
}

.awsui_drawer-content_1uo6m_dgeyf_215:not(#\9) {
  position: fixed;
  overflow: auto;
  background-color: var(--color-background-layout-panel-content-b4s8gn, #ffffff);
  display: flex;
  flex-direction: column;
}
.awsui_drawer-mobile_1uo6m_dgeyf_205 > .awsui_drawer-content_1uo6m_dgeyf_215:not(#\9) {
  z-index: 1001;
  inset: 0;
}
.awsui_drawer-closed_1uo6m_dgeyf_208 > .awsui_drawer-content_1uo6m_dgeyf_215:not(#\9) {
  inline-size: 40px;
}
.awsui_drawer-closed_1uo6m_dgeyf_208 > .awsui_drawer-content_1uo6m_dgeyf_215.awsui_drawer-content-clickable_1uo6m_dgeyf_229:not(#\9) {
  cursor: pointer;
  color: var(--color-text-interactive-default-tbacaa, #424650);
}
.awsui_drawer-closed_1uo6m_dgeyf_208 > .awsui_drawer-content_1uo6m_dgeyf_215.awsui_drawer-content-clickable_1uo6m_dgeyf_229:not(#\9):hover {
  background: var(--color-background-layout-panel-hover-1ftnua, #ebebf0);
}
.awsui_drawer-content_1uo6m_dgeyf_215 > [aria-hidden=true]:not(#\9) {
  display: none;
}
.awsui_drawer-content_1uo6m_dgeyf_215 > .awsui_drawer-resize-content_1uo6m_dgeyf_239:not(#\9) {
  overflow: auto;
  block-size: 100%;
  position: relative;
}
.awsui_drawer-content_1uo6m_dgeyf_215 > .awsui_drawer-content-wrapper_1uo6m_dgeyf_244:not(#\9) {
  flex: 1;
}

.awsui_drawer-triggers-wrapper_1uo6m_dgeyf_248:not(#\9) {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: stretch;
}

.awsui_drawer-trigger_1uo6m_dgeyf_195:not(#\9) {
  padding-block: var(--space-xxs-p8yyaw, 4px);
  padding-inline: 7px;
  cursor: pointer;
  color: var(--color-text-interactive-default-tbacaa, #424650);
}
.awsui_drawer-trigger_1uo6m_dgeyf_195:not(#\9):not(:first-child) {
  border-block-start: 1px solid var(--color-border-layout-hrw5xj, #b4b4bb);
}
.awsui_drawer-trigger_1uo6m_dgeyf_195:not(#\9):hover {
  color: var(--color-text-layout-toggle-hover-v3lnw5, #006ce0);
}
.awsui_drawer-trigger-active_1uo6m_dgeyf_267:not(#\9), .awsui_drawer-trigger-active_1uo6m_dgeyf_267:not(#\9):hover {
  background-color: var(--color-background-layout-toggle-selected-default-27rnk6, #006ce0);
  color: var(--color-text-layout-toggle-active-as4nbt, #ffffff);
}
.awsui_drawer-content-clickable_1uo6m_dgeyf_229 > .awsui_drawer-triggers-wrapper_1uo6m_dgeyf_248 > .awsui_drawer-trigger_1uo6m_dgeyf_195:not(#\9):hover {
  color: var(--color-text-interactive-default-tbacaa, #424650);
}

.awsui_resize-handle-wrapper_1uo6m_dgeyf_275:not(#\9) {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
  block-size: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
}

.awsui_hide_1uo6m_dgeyf_285:not(#\9) {
  display: none;
}