/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_icon-shake_1cbgc_j52ny_149:not(#\9) {
  display: inline-block;
  animation: awsui_awsui-motion-shake-horizontally_1cbgc_j52ny_1 var(--motion-duration-refresh-only-medium-nf6485, 165ms);
}
@keyframes awsui_awsui-motion-shake-horizontally_1cbgc_j52ny_1 {
  0% {
    transform: translateX(-5px);
    animation-timing-function: linear;
  }
  50% {
    transform: translateX(5px);
    animation-timing-function: var(--motion-easing-refresh-only-a-8flf9q, cubic-bezier(0, 0, 0, 1));
  }
  100% {
    transform: translateX(0px);
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_icon-shake_1cbgc_j52ny_149:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_icon-shake_1cbgc_j52ny_149:not(#\9), .awsui-mode-entering .awsui_icon-shake_1cbgc_j52ny_149:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_container-fade-in_1cbgc_j52ny_177:not(#\9) {
  animation: awsui_awsui-motion-fade-in-0_1cbgc_j52ny_1 var(--motion-duration-refresh-only-medium-nf6485, 165ms) var(--motion-easing-refresh-only-a-8flf9q, cubic-bezier(0, 0, 0, 1));
}
@keyframes awsui_awsui-motion-fade-in-0_1cbgc_j52ny_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_container-fade-in_1cbgc_j52ny_177:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_container-fade-in_1cbgc_j52ny_177:not(#\9), .awsui-mode-entering .awsui_container-fade-in_1cbgc_j52ny_177:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_root_1cbgc_j52ny_199:not(#\9) {
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}
.awsui_root_1cbgc_j52ny_199.awsui_status-error_1cbgc_j52ny_208:not(#\9) {
  color: var(--color-text-status-error-5676bj, #db0000);
}
.awsui_root_1cbgc_j52ny_199.awsui_status-warning_1cbgc_j52ny_211:not(#\9) {
  color: var(--color-text-status-warning-csaw41, #855900);
}
.awsui_root_1cbgc_j52ny_199.awsui_status-success_1cbgc_j52ny_214:not(#\9) {
  color: var(--color-text-status-success-gqp5xk, #00802f);
}
.awsui_root_1cbgc_j52ny_199.awsui_status-info_1cbgc_j52ny_217:not(#\9) {
  color: var(--color-text-status-info-7rlubr, #006ce0);
}
.awsui_root_1cbgc_j52ny_199.awsui_status-stopped_1cbgc_j52ny_220:not(#\9) {
  color: var(--color-text-status-inactive-5megna, #656871);
}
.awsui_root_1cbgc_j52ny_199.awsui_status-pending_1cbgc_j52ny_223:not(#\9) {
  color: var(--color-text-status-inactive-5megna, #656871);
}
.awsui_root_1cbgc_j52ny_199.awsui_status-in-progress_1cbgc_j52ny_226:not(#\9) {
  color: var(--color-text-status-inactive-5megna, #656871);
}
.awsui_root_1cbgc_j52ny_199.awsui_status-loading_1cbgc_j52ny_229:not(#\9) {
  color: var(--color-text-status-inactive-5megna, #656871);
}
.awsui_root_1cbgc_j52ny_199.awsui_color-override-red_1cbgc_j52ny_232:not(#\9) {
  color: var(--color-text-status-error-5676bj, #db0000);
}
.awsui_root_1cbgc_j52ny_199.awsui_color-override-grey_1cbgc_j52ny_235:not(#\9) {
  color: var(--color-text-status-inactive-5megna, #656871);
}
.awsui_root_1cbgc_j52ny_199.awsui_color-override-blue_1cbgc_j52ny_238:not(#\9) {
  color: var(--color-text-status-info-7rlubr, #006ce0);
}
.awsui_root_1cbgc_j52ny_199.awsui_color-override-green_1cbgc_j52ny_241:not(#\9) {
  color: var(--color-text-status-success-gqp5xk, #00802f);
}
.awsui_root_1cbgc_j52ny_199.awsui_color-override-yellow_1cbgc_j52ny_244:not(#\9) {
  color: var(--color-text-status-warning-csaw41, #855900);
}

.awsui_container_1cbgc_j52ny_177.awsui_display-inline_1cbgc_j52ny_248:not(#\9) {
  min-inline-size: 0;
  word-break: break-word;
  display: inline;
}
.awsui_container_1cbgc_j52ny_177.awsui_display-inline_1cbgc_j52ny_248 > .awsui_icon_1cbgc_j52ny_149:not(#\9) {
  white-space: nowrap;
}
.awsui_container_1cbgc_j52ny_177.awsui_display-inline-block_1cbgc_j52ny_256:not(#\9) {
  display: inline-block;
  word-wrap: break-word;
  word-break: break-all;
}
.awsui_container_1cbgc_j52ny_177.awsui_display-inline-block_1cbgc_j52ny_256 > .awsui_icon_1cbgc_j52ny_149:not(#\9) {
  padding-inline-end: var(--space-xxs-p8yyaw, 4px);
}

.awsui_overflow-ellipsis_1cbgc_j52ny_265:not(#\9) {
  max-inline-size: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: text-bottom;
}