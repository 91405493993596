/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_expandable-toggle-wrapper_c6tup_1v91i_141:not(#\9) {
  position: absolute;
  inset-block: 0;
  display: flex;
  align-items: center;
}

.awsui_body-cell_c6tup_1v91i_148:not(#\9) {
  box-sizing: border-box;
  border-block-start: var(--border-divider-list-width-27y3k5, 1px) solid transparent;
  border-block-end: var(--border-divider-list-width-27y3k5, 1px) solid var(--color-border-divider-secondary-alwp8z, #ebebf0);
  word-wrap: break-word;
  font-weight: inherit;
  text-align: start;
}
.awsui_body-cell_c6tup_1v91i_148 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141:not(#\9) {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-0_c6tup_1v91i_162 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 0 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-0_c6tup_1v91i_162 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141:not(#\9) {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + -1 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-1_c6tup_1v91i_169 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 1 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-1_c6tup_1v91i_169 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141:not(#\9) {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 0 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-2_c6tup_1v91i_176 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 2 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-2_c6tup_1v91i_176 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141:not(#\9) {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 1 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-3_c6tup_1v91i_183 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 3 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-3_c6tup_1v91i_183 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141:not(#\9) {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-4_c6tup_1v91i_190 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 4 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-4_c6tup_1v91i_190 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141:not(#\9) {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 3 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-5_c6tup_1v91i_197 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 5 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-5_c6tup_1v91i_197 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141:not(#\9) {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 4 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-6_c6tup_1v91i_204 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 6 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-6_c6tup_1v91i_204 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141:not(#\9) {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 5 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-7_c6tup_1v91i_211 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 7 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-7_c6tup_1v91i_211 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141:not(#\9) {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 6 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-8_c6tup_1v91i_218 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 8 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-8_c6tup_1v91i_218 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141:not(#\9) {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 7 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-9_c6tup_1v91i_225 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 9 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-9_c6tup_1v91i_225 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141:not(#\9) {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 8 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-next_c6tup_1v91i_232 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 9 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_expandable-level-next_c6tup_1v91i_232 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141:not(#\9) {
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) + (9 - 1) * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-block-start: calc(var(--space-scaled-xs-26e2du, 8px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2px);
  margin-block-start: calc(-1 * 2px);
}
.awsui_body-cell_c6tup_1v91i_148 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-block-end: calc(calc(var(--space-scaled-xs-26e2du, 8px) + var(--border-item-width-yel47s, 2px) - var(--border-divider-list-width-27y3k5, 1px)) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2px);
  margin-block-end: calc(-1 * 2px);
}
.awsui_body-cell_c6tup_1v91i_148 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-inline-end: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  box-sizing: border-box;
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):not(.awsui_body-cell-wrap_c6tup_1v91i_253) > .awsui_body-cell-content_c6tup_1v91i_156 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child {
  border-inline-start: var(--border-item-width-yel47s, 2px) solid transparent;
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):last-child {
  border-inline-end: var(--border-item-width-yel47s, 2px) solid transparent;
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):last-child > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-end: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px)) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child {
  /*
    Striped rows requires additional left padding because the
    shaded background makes the child content appear too close
    to the table edge.
  */
  /*
    Remove the placeholder border if the row is not selectable.
    Rows that are not selectable will reserve the horizontal space
    that the placeholder border would consume.
  */
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279) > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279) > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-0_c6tup_1v91i_162 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 0 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-0_c6tup_1v91i_162 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + -1 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-1_c6tup_1v91i_169 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 1 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-1_c6tup_1v91i_169 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 0 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-2_c6tup_1v91i_176 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 2 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-2_c6tup_1v91i_176 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 1 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-3_c6tup_1v91i_183 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 3 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-3_c6tup_1v91i_183 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-4_c6tup_1v91i_190 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 4 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-4_c6tup_1v91i_190 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 3 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-5_c6tup_1v91i_197 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 5 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-5_c6tup_1v91i_197 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 4 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-6_c6tup_1v91i_204 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 6 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-6_c6tup_1v91i_204 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 5 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-7_c6tup_1v91i_211 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 7 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-7_c6tup_1v91i_211 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 6 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-8_c6tup_1v91i_218 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 8 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-8_c6tup_1v91i_218 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 7 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-9_c6tup_1v91i_225 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 9 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-9_c6tup_1v91i_225 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 8 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-next_c6tup_1v91i_232 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 9 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-next_c6tup_1v91i_232 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) + (9 - 1) * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-0_c6tup_1v91i_162 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 0 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-0_c6tup_1v91i_162 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + -1 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-1_c6tup_1v91i_169 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 1 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-1_c6tup_1v91i_169 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 0 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-2_c6tup_1v91i_176 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 2 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-2_c6tup_1v91i_176 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 1 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-3_c6tup_1v91i_183 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 3 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-3_c6tup_1v91i_183 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-4_c6tup_1v91i_190 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 4 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-4_c6tup_1v91i_190 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 3 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-5_c6tup_1v91i_197 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 5 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-5_c6tup_1v91i_197 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 4 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-6_c6tup_1v91i_204 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 6 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-6_c6tup_1v91i_204 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 5 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-7_c6tup_1v91i_211 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 7 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-7_c6tup_1v91i_211 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 6 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-8_c6tup_1v91i_218 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 8 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-8_c6tup_1v91i_218 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 7 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-9_c6tup_1v91i_225 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 9 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-9_c6tup_1v91i_225 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 8 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-next_c6tup_1v91i_232 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 9 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-editable_c6tup_1v91i_362:hover.awsui_expandable-level-next_c6tup_1v91i_232 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(calc(var(--space-xxxs-zbmxqb, 2px) + var(--border-divider-list-width-27y3k5, 1px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) + (9 - 1) * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445) > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445) > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-0_c6tup_1v91i_162 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 0 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-0_c6tup_1v91i_162 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + -1 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-1_c6tup_1v91i_169 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 1 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-1_c6tup_1v91i_169 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 0 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-2_c6tup_1v91i_176 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 2 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-2_c6tup_1v91i_176 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 1 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-3_c6tup_1v91i_183 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 3 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-3_c6tup_1v91i_183 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-4_c6tup_1v91i_190 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 4 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-4_c6tup_1v91i_190 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 3 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-5_c6tup_1v91i_197 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 5 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-5_c6tup_1v91i_197 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 4 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-6_c6tup_1v91i_204 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 6 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-6_c6tup_1v91i_204 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 5 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-7_c6tup_1v91i_211 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 7 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-7_c6tup_1v91i_211 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 6 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-8_c6tup_1v91i_218 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 8 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-8_c6tup_1v91i_218 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 7 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-9_c6tup_1v91i_225 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 9 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-9_c6tup_1v91i_225 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 8 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-next_c6tup_1v91i_232 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 9 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445:not(.awsui_has-selection_c6tup_1v91i_445).awsui_expandable-level-next_c6tup_1v91i_232 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) + (9 - 1) * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-0_c6tup_1v91i_162 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 0 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-0_c6tup_1v91i_162 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + -1 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-1_c6tup_1v91i_169 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 1 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-1_c6tup_1v91i_169 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 0 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-2_c6tup_1v91i_176 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 2 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-2_c6tup_1v91i_176 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 1 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-3_c6tup_1v91i_183 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 3 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-3_c6tup_1v91i_183 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-4_c6tup_1v91i_190 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 4 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-4_c6tup_1v91i_190 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 3 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-5_c6tup_1v91i_197 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 5 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-5_c6tup_1v91i_197 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 4 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-6_c6tup_1v91i_204 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 6 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-6_c6tup_1v91i_204 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 5 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-7_c6tup_1v91i_211 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 7 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-7_c6tup_1v91i_211 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 6 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-8_c6tup_1v91i_218 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 8 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-8_c6tup_1v91i_218 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 7 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-9_c6tup_1v91i_225 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 9 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-9_c6tup_1v91i_225 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 8 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-next_c6tup_1v91i_232 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 9 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_expandable-level-next_c6tup_1v91i_232 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(calc(var(--space-xxs-p8yyaw, 4px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) + (9 - 1) * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-0_c6tup_1v91i_162 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 0 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-0_c6tup_1v91i_162 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + -1 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-1_c6tup_1v91i_169 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 1 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-1_c6tup_1v91i_169 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 0 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-2_c6tup_1v91i_176 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 2 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-2_c6tup_1v91i_176 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 1 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-3_c6tup_1v91i_183 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 3 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-3_c6tup_1v91i_183 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-4_c6tup_1v91i_190 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 4 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-4_c6tup_1v91i_190 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 3 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-5_c6tup_1v91i_197 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 5 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-5_c6tup_1v91i_197 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 4 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-6_c6tup_1v91i_204 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 6 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-6_c6tup_1v91i_204 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 5 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-7_c6tup_1v91i_211 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 7 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-7_c6tup_1v91i_211 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 6 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-8_c6tup_1v91i_218 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 8 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-8_c6tup_1v91i_218 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 7 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-9_c6tup_1v91i_225 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 9 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-9_c6tup_1v91i_225 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 8 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-next_c6tup_1v91i_232 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 9 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:first-child.awsui_has-striped-rows_c6tup_1v91i_279.awsui_sticky-cell-pad-inline-start_c6tup_1v91i_445.awsui_expandable-level-next_c6tup_1v91i_232 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(calc(var(--space-scaled-l-0hpmd7, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) + (9 - 1) * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child:not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_body-cell-editable_c6tup_1v91i_362) {
  border-inline-start: none;
}
.awsui_body-cell_c6tup_1v91i_148.awsui_is-visual-refresh_c6tup_1v91i_267:not(#\9):first-child > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: var(--border-divider-list-width-27y3k5, 1px);
  margin-inline-start: calc(-1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267) > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267) > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-0_c6tup_1v91i_162 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 0 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-0_c6tup_1v91i_162 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + -1 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-1_c6tup_1v91i_169 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 1 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-1_c6tup_1v91i_169 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 0 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-2_c6tup_1v91i_176 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 2 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-2_c6tup_1v91i_176 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 1 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-3_c6tup_1v91i_183 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 3 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-3_c6tup_1v91i_183 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-4_c6tup_1v91i_190 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 4 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-4_c6tup_1v91i_190 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 3 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-5_c6tup_1v91i_197 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 5 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-5_c6tup_1v91i_197 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 4 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-6_c6tup_1v91i_204 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 6 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-6_c6tup_1v91i_204 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 5 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-7_c6tup_1v91i_211 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 7 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-7_c6tup_1v91i_211 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 6 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-8_c6tup_1v91i_218 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 8 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-8_c6tup_1v91i_218 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 7 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-9_c6tup_1v91i_225 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 9 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-9_c6tup_1v91i_225 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 8 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-next_c6tup_1v91i_232 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 9 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_is-visual-refresh_c6tup_1v91i_267).awsui_expandable-level-next_c6tup_1v91i_232 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(calc(var(--space-l-t419sm, 20px) - var(--border-item-width-yel47s, 2px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) + (9 - 1) * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell-align-top_c6tup_1v91i_784:not(#\9) {
  vertical-align: top;
}
.awsui_body-cell-first-row_c6tup_1v91i_787:not(#\9) {
  border-block-start: var(--border-item-width-yel47s, 2px) solid transparent;
}
.awsui_body-cell-last-row_c6tup_1v91i_790:not(#\9):not(.awsui_body-cell-selected_c6tup_1v91i_790):not(.awsui_has-footer_c6tup_1v91i_790) {
  border-block-end: var(--border-item-width-yel47s, 2px) solid transparent;
}
.awsui_body-cell-last-row_c6tup_1v91i_790:not(#\9):not(.awsui_body-cell-selected_c6tup_1v91i_790).awsui_has-footer_c6tup_1v91i_790 {
  /*
  Add a bottom border to the body cells of the last row as a separator between the
  table and the footer
  */
  border-block-end: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
}
.awsui_body-cell-shaded_c6tup_1v91i_800:not(#\9) {
  background: var(--color-background-cell-shaded-sc089k, #f6f6f9);
}
.awsui_body-cell-selected_c6tup_1v91i_790:not(#\9) {
  background-color: var(--color-background-item-selected-njak80, #f0fbff);
  border-block-start: var(--border-item-width-yel47s, 2px) solid var(--color-border-item-selected-m769ej, #006ce0);
  border-block-end: var(--border-item-width-yel47s, 2px) solid var(--color-border-item-selected-m769ej, #006ce0);
}
.awsui_body-cell-selected_c6tup_1v91i_790 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-block-end: calc(var(--space-scaled-xs-26e2du, 8px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2px);
  margin-block-end: calc(-1 * 2px);
}
.awsui_body-cell-selected_c6tup_1v91i_790.awsui_body-cell-last-row_c6tup_1v91i_790.awsui_is-visual-refresh_c6tup_1v91i_267 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-block-end: calc(calc(var(--space-scaled-xs-26e2du, 8px) + var(--border-divider-list-width-27y3k5, 1px)) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2px);
  margin-block-end: calc(-1 * 2px);
}
.awsui_body-cell-selected_c6tup_1v91i_790:not(#\9):first-child {
  border-inline-start: var(--border-item-width-yel47s, 2px) solid var(--color-border-item-selected-m769ej, #006ce0);
  border-start-start-radius: var(--border-radius-item-05df9h, 8px);
  border-start-end-radius: 0;
  border-end-start-radius: var(--border-radius-item-05df9h, 8px);
  border-end-end-radius: 0;
}
.awsui_body-cell-selected_c6tup_1v91i_790:not(#\9):last-child {
  border-inline-end: var(--border-item-width-yel47s, 2px) solid var(--color-border-item-selected-m769ej, #006ce0);
  border-start-start-radius: 0;
  border-start-end-radius: var(--border-radius-item-05df9h, 8px);
  border-end-start-radius: 0;
  border-end-end-radius: var(--border-radius-item-05df9h, 8px);
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell_c6tup_1v91i_445:not(#\9) {
  position: sticky;
  background: var(--color-background-container-content-epgqkc, #ffffff);
  z-index: 798;
  transition-property: padding;
  transition-duration: var(--motion-duration-transition-show-quick-osg026, 90ms);
  transition-timing-function: var(--motion-easing-sticky-56u8cd, cubic-bezier(1, 0, 0.83, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell_c6tup_1v91i_445:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell_c6tup_1v91i_445:not(#\9), .awsui-mode-entering .awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell_c6tup_1v91i_445:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279) > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279) > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-0_c6tup_1v91i_162 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 0 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-0_c6tup_1v91i_162 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + -1 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-1_c6tup_1v91i_169 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 1 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-1_c6tup_1v91i_169 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 0 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-2_c6tup_1v91i_176 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 2 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-2_c6tup_1v91i_176 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 1 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-3_c6tup_1v91i_183 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 3 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-3_c6tup_1v91i_183 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-4_c6tup_1v91i_190 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 4 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-4_c6tup_1v91i_190 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 3 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-5_c6tup_1v91i_197 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 5 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-5_c6tup_1v91i_197 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 4 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-6_c6tup_1v91i_204 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 6 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-6_c6tup_1v91i_204 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 5 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-7_c6tup_1v91i_211 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 7 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-7_c6tup_1v91i_211 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 6 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-8_c6tup_1v91i_218 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 8 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-8_c6tup_1v91i_218 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 7 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-9_c6tup_1v91i_225 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 9 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-9_c6tup_1v91i_225 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 8 * (var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-next_c6tup_1v91i_232 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2);
  margin-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) / 2 + 9 * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-pad-left_c6tup_1v91i_848:not(#\9):not(.awsui_has-selection_c6tup_1v91i_445):not(.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:first-child.awsui_has-striped-rows_c6tup_1v91i_279).awsui_expandable-level-next_c6tup_1v91i_232 > .awsui_expandable-toggle-wrapper_c6tup_1v91i_141 {
  margin-inline-start: calc(calc(var(--space-table-horizontal-8davlm, 20px) - 1 * var(--border-divider-list-width-27y3k5, 1px)) + (9 - 1) * calc(var(--space-m-udix3p, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell_c6tup_1v91i_445.awsui_body-cell-shaded_c6tup_1v91i_800:not(#\9) {
  background: var(--color-background-cell-shaded-sc089k, #f6f6f9);
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell_c6tup_1v91i_445.awsui_body-cell-selected_c6tup_1v91i_790:not(#\9) {
  background-color: var(--color-background-item-selected-njak80, #f0fbff);
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell_c6tup_1v91i_445.awsui_body-cell-selected_c6tup_1v91i_790:not(#\9):first-child {
  box-shadow: 0 0 0 4px var(--color-background-container-content-epgqkc, #ffffff);
  clip-path: inset(0 0 0 0);
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell_c6tup_1v91i_445.awsui_body-cell-selected_c6tup_1v91i_790:not(#\9):last-child {
  box-shadow: 4px 0 0 0 var(--color-background-container-content-epgqkc, #ffffff);
  clip-path: inset(0 0 0 0);
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell_c6tup_1v91i_445.awsui_body-cell-selected_c6tup_1v91i_790:not(#\9):last-child.awsui_sticky-cell-last-inline-end_c6tup_1v91i_945 {
  box-shadow: var(--shadow-sticky-column-last-n170vw, -4px 0 8px 1px rgba(0, 28, 36, 0.1)), 8px 0 0 0 var(--color-background-container-content-epgqkc, #ffffff);
  clip-path: inset(0 0 0 -24px);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell_c6tup_1v91i_445.awsui_body-cell-selected_c6tup_1v91i_790:not(#\9):last-child.awsui_sticky-cell-last-inline-end_c6tup_1v91i_945:dir(rtl) {
  box-shadow: var(--shadow-sticky-column-first-039dqs, 4px 0px 8px 1px rgba(0, 7, 22, 0.1));
  clip-path: inset(0 -24px 0 0);
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-last-inline-start_c6tup_1v91i_954:not(#\9) {
  box-shadow: var(--shadow-sticky-column-first-039dqs, 4px 0px 8px 1px rgba(0, 7, 22, 0.1));
  clip-path: inset(0px -24px 0px 0px);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-last-inline-start_c6tup_1v91i_954:not(#\9):dir(rtl) {
  box-shadow: var(--shadow-sticky-column-last-n170vw, -4px 0 8px 1px rgba(0, 28, 36, 0.1));
  clip-path: inset(0 0 0 -24px);
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-last-inline-end_c6tup_1v91i_945:not(#\9) {
  box-shadow: var(--shadow-sticky-column-last-n170vw, -4px 0 8px 1px rgba(0, 28, 36, 0.1));
  clip-path: inset(0 0 0 -24px);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}
.awsui_body-cell_c6tup_1v91i_148.awsui_sticky-cell-last-inline-end_c6tup_1v91i_945:not(#\9):dir(rtl) {
  box-shadow: var(--shadow-sticky-column-first-039dqs, 4px 0px 8px 1px rgba(0, 7, 22, 0.1));
  clip-path: inset(0 -24px 0 0);
}
.awsui_body-cell-selected_c6tup_1v91i_790:not(#\9):not(:first-child) > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-block-start: calc(calc(var(--space-scaled-xs-26e2du, 8px) + var(--border-item-width-yel47s, 2px) - var(--border-divider-list-width-27y3k5, 1px)) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2px);
  margin-block-start: calc(-1 * 2px);
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):not(.awsui_body-cell-selected_c6tup_1v91i_790).awsui_body-cell-next-selected_c6tup_1v91i_976 {
  border-block-end: 0;
}
.awsui_body-cell_c6tup_1v91i_148:not(#\9):not(.awsui_body-cell-selected_c6tup_1v91i_790).awsui_body-cell-next-selected_c6tup_1v91i_976 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-block-end: calc(calc(var(--space-scaled-xs-26e2du, 8px) + var(--border-divider-list-width-27y3k5, 1px)) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2px);
  margin-block-end: calc(-1 * 2px);
}
.awsui_body-cell-selected_c6tup_1v91i_790.awsui_body-cell-prev-selected_c6tup_1v91i_983:not(#\9) {
  border-block-start: var(--border-divider-list-width-27y3k5, 1px) solid var(--color-border-item-placeholder-h6zoax, #006ce0);
}
.awsui_body-cell-selected_c6tup_1v91i_790.awsui_body-cell-prev-selected_c6tup_1v91i_983 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  padding-block-start: calc(calc(var(--space-scaled-xs-26e2du, 8px) + var(--border-item-width-yel47s, 2px) - var(--border-divider-list-width-27y3k5, 1px)) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2px);
  margin-block-start: calc(-1 * 2px);
}
.awsui_body-cell-selected_c6tup_1v91i_790.awsui_body-cell-next-selected_c6tup_1v91i_976:not(#\9) {
  border-block-end-width: var(--border-divider-list-width-27y3k5, 1px);
}
.awsui_body-cell-selected_c6tup_1v91i_790.awsui_body-cell-next-selected_c6tup_1v91i_976:not(#\9):first-child {
  border-end-start-radius: 0;
}
.awsui_body-cell-selected_c6tup_1v91i_790.awsui_body-cell-next-selected_c6tup_1v91i_976:not(#\9):last-child {
  border-end-end-radius: 0;
}
.awsui_body-cell-selected_c6tup_1v91i_790.awsui_body-cell-prev-selected_c6tup_1v91i_983:not(#\9):first-child {
  border-start-start-radius: 0;
}
.awsui_body-cell-selected_c6tup_1v91i_790.awsui_body-cell-prev-selected_c6tup_1v91i_983:not(#\9):last-child {
  border-start-end-radius: 0;
}
.awsui_body-cell-selected_c6tup_1v91i_790:not(#\9):not(.awsui_body-cell-prev-selected_c6tup_1v91i_983) > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-block-start: calc(var(--space-scaled-xs-26e2du, 8px) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2px);
  margin-block-start: calc(-1 * 2px);
}
.awsui_body-cell-editor-wrapper_c6tup_1v91i_1009:not(#\9) {
  padding-block: 0;
  padding-inline-start: 0;
  padding-inline-end: calc(var(--space-xs-zb16t3, 8px) + var(--space-xxs-p8yyaw, 4px));
}
.awsui_body-cell-success_c6tup_1v91i_1014:not(#\9) {
  padding-block: 0;
  padding-inline-start: 0;
  padding-inline-end: calc(calc(var(--space-xs-zb16t3, 8px) + var(--space-xxs-p8yyaw, 4px)) + calc(var(--size-icon-normal-0m1722, 16px) + var(--space-xs-zb16t3, 8px)));
}
.awsui_body-cell-success_c6tup_1v91i_1014:not(#\9), .awsui_body-cell-editor-wrapper_c6tup_1v91i_1009:not(#\9) {
  inset-block: 0;
  inset-inline-end: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.awsui_body-cell-editor_c6tup_1v91i_1009:not(#\9) {
  cursor: pointer;
  outline: 0;
  background: 0;
  border-block: 0;
  border-inline: 0;
  padding-block: 0;
  padding-inline: 0;
  min-block-size: 10px;
  min-inline-size: 10px;
  color: var(--color-text-button-normal-default-das2ly, #006ce0);
}
.awsui_body-cell-editor-disabled_c6tup_1v91i_1039:not(#\9) {
  color: var(--color-text-disabled-inline-edit-rcb2pm, #424650);
}
.awsui_body-cell-editor_c6tup_1v91i_1009:not(#\9):hover {
  color: var(--color-text-button-normal-hover-pmztmi, #002b66);
}
.awsui_body-cell-editor_c6tup_1v91i_1009:not(#\9):active {
  color: var(--color-text-button-normal-active-fdnwsh, #002b66);
}
.awsui_body-cell-editor-form_c6tup_1v91i_1048:not(#\9) {
  margin-block: calc(-1 * var(--space-xs-zb16t3, 8px));
  margin-inline: calc(-1.5 * var(--space-xs-zb16t3, 8px));
}
.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child.awsui_has-striped-rows_c6tup_1v91i_279 > .awsui_body-cell-editor-form_c6tup_1v91i_1048 {
  margin-inline-start: calc(-1 * var(--space-xxs-p8yyaw, 4px));
}
.awsui_is-visual-refresh_c6tup_1v91i_267.awsui_body-cell_c6tup_1v91i_148:not(#\9):first-child:not(.awsui_has-striped-rows_c6tup_1v91i_279) > .awsui_body-cell-editor-form_c6tup_1v91i_1048 {
  margin-inline-start: calc(-1 * var(--space-xxxs-zbmxqb, 2px));
}
.awsui_body-cell-editor-row_c6tup_1v91i_1058:not(#\9) {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  column-gap: var(--space-xxs-p8yyaw, 4px);
}
.awsui_body-cell-editor-row_c6tup_1v91i_1058 > :not(#\9):not(:last-child) {
  flex-grow: 1;
}
.awsui_body-cell-editor-controls_c6tup_1v91i_1068:not(#\9) {
  flex-shrink: 0;
}
.awsui_body-cell-editor-row-editor_c6tup_1v91i_1071:not(#\9) {
  max-inline-size: calc(100% - 6 * var(--space-xxs-p8yyaw, 4px) - 2 * var(--size-icon-normal-0m1722, 16px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-expandable_c6tup_1v91i_1074:not(#\9) {
  position: relative;
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-expandable_c6tup_1v91i_1074.awsui_sticky-cell_c6tup_1v91i_445:not(#\9) {
  position: sticky;
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9) {
  position: relative;
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362.awsui_sticky-cell_c6tup_1v91i_445:not(#\9) {
  position: sticky;
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362.awsui_body-cell-edit-active_c6tup_1v91i_362 > .awsui_body-cell-content_c6tup_1v91i_156:not(#\9) {
  overflow: visible;
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362) {
  cursor: pointer;
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):not(.awsui_resizable-columns_c6tup_1v91i_1092) > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-end: calc(calc(var(--space-scaled-l-0hpmd7, 20px) + var(--space-l-t419sm, 20px)) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362) > .awsui_body-cell-editor-wrapper_c6tup_1v91i_1009, .awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362) > .awsui_expandable-cell-content_c6tup_1v91i_1095 > .awsui_body-cell-editor-wrapper_c6tup_1v91i_1009 {
  opacity: 0;
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):focus-within {
  position: relative;
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):focus-within {
  outline: 2px dotted transparent;
  outline-offset: calc(calc(-1 * var(--space-scaled-xxs-7597g1, 4px)) - 1px);
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):focus-within::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * calc(-1 * var(--space-scaled-xxs-7597g1, 4px)));
  inset-block-start: calc(-1 * calc(-1 * var(--space-scaled-xxs-7597g1, 4px)));
  inline-size: calc(100% + calc(-1 * var(--space-scaled-xxs-7597g1, 4px)) + calc(-1 * var(--space-scaled-xxs-7597g1, 4px)));
  block-size: calc(100% + calc(-1 * var(--space-scaled-xxs-7597g1, 4px)) + calc(-1 * var(--space-scaled-xxs-7597g1, 4px)));
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):focus-within.awsui_is-visual-refresh_c6tup_1v91i_267:first-child {
  position: relative;
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):focus-within.awsui_is-visual-refresh_c6tup_1v91i_267:first-child {
  outline: 2px dotted transparent;
  outline-offset: calc(calc(1 * var(--space-scaled-xxs-7597g1, 4px)) - 1px);
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):focus-within.awsui_is-visual-refresh_c6tup_1v91i_267:first-child::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * calc(1 * var(--space-scaled-xxs-7597g1, 4px)));
  inset-block-start: calc(-1 * calc(-1 * var(--space-scaled-xxs-7597g1, 4px)));
  inline-size: calc(100% + calc(1 * var(--space-scaled-xxs-7597g1, 4px)) + calc(-1 * var(--space-scaled-xxs-7597g1, 4px)));
  block-size: calc(100% + calc(-1 * var(--space-scaled-xxs-7597g1, 4px)) + calc(-1 * var(--space-scaled-xxs-7597g1, 4px)));
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):focus-within.awsui_sticky-cell_c6tup_1v91i_445 {
  position: sticky;
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):focus-within:focus-within.awsui_body-cell-has-success_c6tup_1v91i_1143 > .awsui_body-cell-content_c6tup_1v91i_156, .awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-edit-disabled-popover_c6tup_1v91i_1143.awsui_body-cell-has-success_c6tup_1v91i_1143 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-end: calc(calc(var(--space-scaled-l-0hpmd7, 20px) + var(--space-l-t419sm, 20px) + calc(var(--size-icon-normal-0m1722, 16px) + var(--space-xs-zb16t3, 8px))) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):focus-within:focus-within > .awsui_body-cell-content_c6tup_1v91i_156, .awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-edit-disabled-popover_c6tup_1v91i_1143 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-end: calc(calc(var(--space-scaled-l-0hpmd7, 20px) + var(--space-l-t419sm, 20px)) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):focus-within:focus-within > .awsui_body-cell-editor-wrapper_c6tup_1v91i_1009, .awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):focus-within:focus-within > .awsui_expandable-cell-content_c6tup_1v91i_1095 > .awsui_body-cell-editor-wrapper_c6tup_1v91i_1009, .awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-edit-disabled-popover_c6tup_1v91i_1143 > .awsui_body-cell-editor-wrapper_c6tup_1v91i_1009, .awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-edit-disabled-popover_c6tup_1v91i_1143 > .awsui_expandable-cell-content_c6tup_1v91i_1095 > .awsui_body-cell-editor-wrapper_c6tup_1v91i_1009 {
  opacity: 1;
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):focus-within:focus-within > .awsui_body-cell-success_c6tup_1v91i_1014, .awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-edit-disabled-popover_c6tup_1v91i_1143 > .awsui_body-cell-success_c6tup_1v91i_1014 {
  opacity: 1;
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):focus-within:focus-within.awsui_sticky-cell_c6tup_1v91i_445, .awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362).awsui_body-cell-edit-disabled-popover_c6tup_1v91i_1143.awsui_sticky-cell_c6tup_1v91i_445 {
  position: sticky;
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):hover:hover {
  position: relative;
  background-color: var(--color-background-dropdown-item-hover-zhzc84, #f3f3f7);
  border-block: var(--border-divider-list-width-27y3k5, 1px) solid var(--color-border-editable-cell-hover-3ju944, #8c8c94);
  border-inline: var(--border-divider-list-width-27y3k5, 1px) solid var(--color-border-editable-cell-hover-3ju944, #8c8c94);
  inset-inline: calc(-1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):hover:hover.awsui_sticky-cell_c6tup_1v91i_445 {
  position: sticky;
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):hover:hover:first-child {
  inset-inline: 0;
  border-start-start-radius: var(--border-radius-item-05df9h, 8px);
  border-end-start-radius: var(--border-radius-item-05df9h, 8px);
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):hover:hover:last-child {
  border-start-end-radius: var(--border-radius-item-05df9h, 8px);
  border-end-end-radius: var(--border-radius-item-05df9h, 8px);
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):hover:hover > .awsui_body-cell-editor-wrapper_c6tup_1v91i_1009 > .awsui_body-cell-content_c6tup_1v91i_156, .awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):hover:hover > .awsui_expandable-cell-content_c6tup_1v91i_1095 > .awsui_body-cell-editor-wrapper_c6tup_1v91i_1009 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-end: calc(calc(calc(var(--space-xs-zb16t3, 8px) + var(--space-xxs-p8yyaw, 4px)) - 2 * var(--border-divider-list-width-27y3k5, 1px)) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):hover:hover > .awsui_body-cell-success_c6tup_1v91i_1014 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-end: calc(calc(calc(calc(var(--space-xs-zb16t3, 8px) + var(--space-xxs-p8yyaw, 4px)) + calc(var(--size-icon-normal-0m1722, 16px) + var(--space-xs-zb16t3, 8px))) - 2 * var(--border-divider-list-width-27y3k5, 1px)) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):hover:hover.awsui_body-cell-last-row_c6tup_1v91i_790.awsui_body-cell-selected_c6tup_1v91i_790 > .awsui_body-cell-content_c6tup_1v91i_156, .awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):hover:hover.awsui_body-cell-next-selected_c6tup_1v91i_976 > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-block: calc(calc(var(--space-scaled-xs-26e2du, 8px) - var(--border-divider-list-width-27y3k5, 1px) / 2) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2px);
  margin-block: calc(-1 * 2px);
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):hover:hover.awsui_body-cell-last-row_c6tup_1v91i_790:not(.awsui_body-cell-selected_c6tup_1v91i_790) > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-block-start: calc(calc(var(--space-scaled-xs-26e2du, 8px) - (var(--border-divider-list-width-27y3k5, 1px))) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2px);
  margin-block-start: calc(-1 * 2px);
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):hover:hover.awsui_body-cell-first-row_c6tup_1v91i_787:not(.awsui_body-cell-selected_c6tup_1v91i_790) > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-block: calc(calc(var(--space-scaled-xs-26e2du, 8px) - (var(--border-divider-list-width-27y3k5, 1px))) - 1 * var(--border-divider-list-width-27y3k5, 1px) + 2px);
  margin-block: calc(-1 * 2px);
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):hover:hover > .awsui_body-cell-content_c6tup_1v91i_156 {
  padding-inline-end: calc(calc(var(--space-scaled-l-0hpmd7, 20px) + var(--space-l-t419sm, 20px)) - 1 * var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):hover:hover > .awsui_body-cell-editor-wrapper_c6tup_1v91i_1009, .awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):hover:hover > .awsui_expandable-cell-content_c6tup_1v91i_1095 > .awsui_body-cell-editor-wrapper_c6tup_1v91i_1009 {
  opacity: 1;
}
.awsui_body-cell_c6tup_1v91i_148.awsui_body-cell-editable_c6tup_1v91i_362:not(#\9):not(.awsui_body-cell-edit-active_c6tup_1v91i_362):hover:hover > .awsui_body-cell-success_c6tup_1v91i_1014 {
  opacity: 1;
}
body[data-awsui-focus-visible=true] .awsui_body-cell_c6tup_1v91i_148:not(#\9):focus {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_body-cell_c6tup_1v91i_148:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(calc(-1 * var(--space-scaled-xxs-7597g1, 4px)) - 1px);
}
body[data-awsui-focus-visible=true] .awsui_body-cell_c6tup_1v91i_148:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * calc(-1 * var(--space-scaled-xxs-7597g1, 4px)));
  inset-block-start: calc(-1 * calc(-1 * var(--space-scaled-xxs-7597g1, 4px)));
  inline-size: calc(100% + calc(-1 * var(--space-scaled-xxs-7597g1, 4px)) + calc(-1 * var(--space-scaled-xxs-7597g1, 4px)));
  block-size: calc(100% + calc(-1 * var(--space-scaled-xxs-7597g1, 4px)) + calc(-1 * var(--space-scaled-xxs-7597g1, 4px)));
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}
body[data-awsui-focus-visible=true] .awsui_body-cell_c6tup_1v91i_148:not(#\9):focus.awsui_is-visual-refresh_c6tup_1v91i_267:first-child {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_body-cell_c6tup_1v91i_148:not(#\9):focus.awsui_is-visual-refresh_c6tup_1v91i_267:first-child {
  outline: 2px dotted transparent;
  outline-offset: calc(calc(1 * var(--space-scaled-xxs-7597g1, 4px)) - 1px);
}
body[data-awsui-focus-visible=true] .awsui_body-cell_c6tup_1v91i_148:not(#\9):focus.awsui_is-visual-refresh_c6tup_1v91i_267:first-child::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * calc(1 * var(--space-scaled-xxs-7597g1, 4px)));
  inset-block-start: calc(-1 * calc(-1 * var(--space-scaled-xxs-7597g1, 4px)));
  inline-size: calc(100% + calc(1 * var(--space-scaled-xxs-7597g1, 4px)) + calc(-1 * var(--space-scaled-xxs-7597g1, 4px)));
  block-size: calc(100% + calc(-1 * var(--space-scaled-xxs-7597g1, 4px)) + calc(-1 * var(--space-scaled-xxs-7597g1, 4px)));
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}
body[data-awsui-focus-visible=true] .awsui_body-cell_c6tup_1v91i_148:not(#\9):focus.awsui_sticky-cell_c6tup_1v91i_445 {
  position: sticky;
}