/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_root_1mabk_1h5uj_141:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: block;
}

.awsui_radio_1mabk_1h5uj_177:not(#\9) {
  /*used in test-utils*/
}

.awsui_radio_1mabk_1h5uj_177 + .awsui_radio_1mabk_1h5uj_177:not(#\9) {
  margin-block-start: var(--space-scaled-xxs-7597g1, 4px);
}

.awsui_radio--has-description_1mabk_1h5uj_185 + .awsui_radio_1mabk_1h5uj_177:not(#\9) {
  margin-block-start: var(--space-scaled-xs-26e2du, 8px);
}

.awsui_radio-control_1mabk_1h5uj_189:not(#\9) {
  margin-block-start: calc((var(--line-height-body-m-30ar75, 20px) - var(--size-control-28i7hr, 16px)) / 2);
  min-block-size: var(--size-control-28i7hr, 16px);
  min-inline-size: var(--size-control-28i7hr, 16px);
  block-size: var(--size-control-28i7hr, 16px);
  inline-size: var(--size-control-28i7hr, 16px);
}

.awsui_outline_1mabk_1h5uj_197:not(#\9) {
  position: relative;
}
.awsui_outline_1mabk_1h5uj_197:not(#\9) {
  outline: 2px dotted transparent;
  outline-offset: calc(2px - 1px);
}
.awsui_outline_1mabk_1h5uj_197:not(#\9)::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * 2px);
  inset-block-start: calc(-1 * 2px);
  inline-size: calc(100% + 2px + 2px);
  block-size: calc(100% + 2px + 2px);
  border-start-start-radius: var(--border-radius-control-circular-focus-ring-w07mub, 4px);
  border-start-end-radius: var(--border-radius-control-circular-focus-ring-w07mub, 4px);
  border-end-start-radius: var(--border-radius-control-circular-focus-ring-w07mub, 4px);
  border-end-end-radius: var(--border-radius-control-circular-focus-ring-w07mub, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}

.awsui_styled-circle-border_1mabk_1h5uj_219:not(#\9) {
  stroke: var(--color-border-control-default-8m5t80, #8c8c94);
  fill: var(--color-background-control-default-5ol8rv, #ffffff);
}
.awsui_styled-circle-border_1mabk_1h5uj_219.awsui_styled-circle-disabled_1mabk_1h5uj_223:not(#\9), .awsui_styled-circle-border_1mabk_1h5uj_219.awsui_styled-circle-readonly_1mabk_1h5uj_223:not(#\9) {
  fill: var(--color-background-control-disabled-5cbvij, #dedee3);
  stroke: var(--color-background-control-disabled-5cbvij, #dedee3);
}

.awsui_styled-circle-fill_1mabk_1h5uj_228:not(#\9) {
  stroke: var(--color-background-control-checked-hz4k40, #006ce0);
  fill: var(--color-foreground-control-default-p84gz5, #ffffff);
  opacity: 0;
  transition: opacity var(--motion-duration-transition-quick-6npj01, 90ms) var(--motion-easing-transition-quick-l9jfsx, linear);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_styled-circle-fill_1mabk_1h5uj_228:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_styled-circle-fill_1mabk_1h5uj_228:not(#\9), .awsui-mode-entering .awsui_styled-circle-fill_1mabk_1h5uj_228:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_styled-circle-fill_1mabk_1h5uj_228.awsui_styled-circle-checked_1mabk_1h5uj_244:not(#\9) {
  opacity: 1;
}
.awsui_styled-circle-fill_1mabk_1h5uj_228.awsui_styled-circle-disabled_1mabk_1h5uj_223:not(#\9) {
  fill: var(--color-foreground-control-disabled-c64ihu, #ffffff);
  stroke: var(--color-background-control-disabled-5cbvij, #dedee3);
}
.awsui_styled-circle-fill_1mabk_1h5uj_228.awsui_styled-circle-readonly_1mabk_1h5uj_223:not(#\9) {
  fill: var(--color-foreground-control-read-only-e43ooq, #656871);
  stroke: var(--color-background-control-disabled-5cbvij, #dedee3);
}